import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  ButtonSubmit,
  Loader,
  PageContent,
  PageHeader,
  Select
} from 'components'
import {
  Discount,
  DiscountItemCategory,
  isDiscountItemCategory,
  Menus
} from 'types'
import { fetchDiscount, selectDiscount, updateDiscount } from 'slices/discount'
import { RequestStatus } from 'utils'
import { useLoading } from 'hooks'
import { fetchMenus, selectMenus } from 'slices/menus'
import {
  fetchCategories,
  resetCategories,
  selectCategories
} from 'slices/categories'
import DiscountedItems from './DiscountedItems'
import { DiscountSelectMenuView } from './DiscountedAndRequiredItems.styled'

interface Props {
  discountId: string
}

const DiscountItems = ({ discountId }: Props) => {
  const dispatch = useAppDispatch()
  const { data: discountData, loading: discountLoading } =
    useAppSelector(selectDiscount)
  const { data: menuData, loading: menusLoading } = useAppSelector(selectMenus)

  const { data: categoriesData, loading: categoriesLoading } =
    useAppSelector(selectCategories)

  const [menuSelected, setMenuSelected] = useState<boolean>(false)
  const [menuId, setMenuId] = useState<string | undefined>()
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [discountedIds, setDiscountedIds] = useState<DiscountItemCategory[]>([])
  const [requiredIds, setRequiredIds] = useState<DiscountItemCategory[]>([])
  const [discountedQuantity, setDiscountedQuantity] = useState<
    number | undefined
  >(0)
  const [requiredQuantity, setRequiredQuantity] = useState<number>(0)

  const isLoading =
    discountLoading === RequestStatus.Pending ||
    menusLoading === RequestStatus.Pending ||
    categoriesLoading === RequestStatus.Pending

  const pageLoading = useLoading(discountId, isLoading)

  const handleFetchDiscount = useCallback(() => {
    if (discountId) {
      dispatch(fetchDiscount({ discountId }))
    }
  }, [dispatch, discountId])

  useEffect(() => {
    setIsLoadingData(true)
    setMenuId('')
    handleFetchDiscount()
  }, [handleFetchDiscount])

  useEffect(() => {
    if (
      !discountData?.discounted_items ||
      !discountData.discounted_items.length
    ) {
      dispatch(fetchMenus())
    }
  }, [discountData, dispatch])

  useEffect(() => {
    if (discountId) {
      dispatch(resetCategories())
    }
  }, [discountId, dispatch])

  useEffect(() => {
    if (
      discountData &&
      discountData.discounted_items &&
      discountData.discounted_items.length
    ) {
      //setting discounted ids
      const discounted_ids = discountData.discounted_items
        .map(item => {
          if (isDiscountItemCategory(item)) {
            return { category_id: item.category_id, item_id: item.item_id }
          }
          return undefined
        })
        .filter((item): item is DiscountItemCategory => !!item)
      setDiscountedIds(discounted_ids)
      //setting required ids
      if (discountData.required_items) {
        const required_ids = discountData.required_items
          .map(item => {
            if (isDiscountItemCategory(item)) {
              return { category_id: item.category_id, item_id: item.item_id }
            }
            return undefined
          })
          .filter((item): item is DiscountItemCategory => !!item)
        setRequiredIds(required_ids)
      }
      setDiscountedQuantity(
        discountData.discounted_quantity ? discountData.discounted_quantity : 0
      )
      setRequiredQuantity(
        discountData.required_quantity ? discountData.required_quantity : 0
      )
      setShowDelete(true)
    }
  }, [discountData])

  const handleFetchCategories = useCallback(
    (menuId: string) => {
      dispatch(
        fetchCategories({
          menu_id: parseInt(menuId),
          is_active: true,
          with_related: 'true',
          limit: 1000
        })
      ).then(() => setIsLoadingData(false))
    },
    [dispatch]
  )

  useEffect(() => {
    if (discountData?.menu_id?.toString()) {
      const menu_id = discountData?.menu_id.toString()
      setMenuId(menu_id)
      handleFetchCategories(menu_id)
    }
  }, [handleFetchCategories, discountData?.menu_id, discountData?.discount_id])

  const generateMenuDataOptions = (menuData: Menus) => {
    return menuData
      ? menuData.map(menu => ({
          value: menu.menu_id,
          text: menu.name
        }))
      : []
  }

  const onMenuChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMenuId = evt.target.value
    setMenuId(selectedMenuId)
  }

  const submit = () => {
    if (menuId) {
      setIsLoadingData(true)
      handleFetchCategories(menuId)
      setMenuSelected(true)
    }
  }

  const submitDiscount = () => {
    if (discountId && discountData) {
      const updatedDiscountData: Discount = {
        ...discountData,
        menu_id: menuId ? parseInt(menuId) : discountData.menu_id,
        discounted_quantity: discountedQuantity,
        required_quantity: requiredQuantity,
        discounted_items: discountedIds,
        required_items: requiredIds,
        entity_type: discountData.entity_type
          ? discountData.entity_type
          : 'MENU_ITEM'
      }
      delete updatedDiscountData.discount_id
      dispatch(
        updateDiscount({ discountId: discountId, data: updatedDiscountData })
      )
      setShowDelete(true)
      setMenuId('')
      window.scroll(0, 0)
    }
  }

  const updateDiscountedValues = (categoryId: number, id: number) => {
    setDiscountedIds(prevDiscountedIds => {
      const existingIndex = prevDiscountedIds.findIndex(
        item => item.item_id === id
      )
      if (existingIndex !== -1) {
        return prevDiscountedIds.filter(item => item.item_id !== id)
      } else {
        return [...prevDiscountedIds, { category_id: categoryId, item_id: id }]
      }
    })
  }

  const updateAllDiscountedValues = (categoryId: number, ids: number[]) => {
    setDiscountedIds(prevState => {
      const filtered = prevState.filter(item => item.category_id !== categoryId)
      const other = ids.map(id => ({ category_id: categoryId, item_id: id }))
      return [...filtered, ...other]
    })
  }

  const updateRequiredValues = (categoryId: number, id: number) => {
    setRequiredIds(prevRequiredIds => {
      const existingIndex = prevRequiredIds.findIndex(
        item => item.item_id === id
      )
      if (existingIndex !== -1) {
        return prevRequiredIds.filter(item => item.item_id !== id)
      } else {
        return [...prevRequiredIds, { category_id: categoryId, item_id: id }]
      }
    })
  }

  const updateAllRequiredValues = (categoryId: number, ids: number[]) => {
    setRequiredIds(prevState => {
      const filtered = prevState.filter(item => item.category_id !== categoryId)
      const other = ids.map(id => ({ category_id: categoryId, item_id: id }))
      return [...filtered, ...other]
    })
  }

  const resetState = () => {
    setDiscountedIds([])
    setDiscountedQuantity(0)
    setRequiredIds([])
    setRequiredQuantity(0)
    setMenuId('')
    setMenuSelected(false)
  }

  const handleDelete = () => {
    if (discountId && discountData) {
      const updatedDiscountData: Discount = {
        ...discountData,
        discounted_quantity: null,
        discounted_items: null,
        entity_type: null,
        menu_id: null,
        required_quantity: null,
        required_items: null
      }
      delete updatedDiscountData.discount_id
      dispatch(
        updateDiscount({ discountId: discountId, data: updatedDiscountData })
      )
      resetState()
    }
  }

  if (!discountId || !discountData) return null

  return (
    <>
      <PageHeader
        title={discountData.name}
        subtitle="Use this page to choose which items should be discounted"
      />
      <PageContent loading={pageLoading}>
        {(!discountData.discounted_items ||
          !discountData.discounted_items.length) &&
        !menuSelected ? (
          <DiscountSelectMenuView>
            <Select
              label="Menu"
              name="menu-id"
              value={menuId}
              options={generateMenuDataOptions(menuData)}
              onChange={onMenuChange}
            />
            <ButtonSubmit text="Submit" color="primary" onClick={submit} />
          </DiscountSelectMenuView>
        ) : isLoadingData ? (
          <Loader />
        ) : (
          <DiscountedItems
            discountedQuantity={discountedQuantity}
            requiredQuantity={requiredQuantity}
            setDiscountedQuantity={setDiscountedQuantity}
            setRequiredQuantity={setRequiredQuantity}
            categoriesData={categoriesData}
            discountedIds={discountedIds}
            requiredIds={requiredIds}
            updateDiscountedValues={updateDiscountedValues}
            updateRequiredValues={updateRequiredValues}
            submit={submitDiscount}
            showDelete={showDelete}
            handleDelete={handleDelete}
            updateAllRequiredValues={updateAllRequiredValues}
            updateAllDiscountedValues={updateAllDiscountedValues}
          />
        )}
      </PageContent>
    </>
  )
}

export default DiscountItems

import { Routes, Route, Navigate } from 'react-router-dom'
import {
  Allergen,
  AllergensOutlet,
  Announcement,
  AnnouncementsOutlet,
  BulkOrder,
  BulkOrders,
  Campaign,
  CampaignOutlet,
  Customer,
  Customers,
  CustomersRoutes,
  Design,
  DesignRoutes,
  Deal,
  DealsOutlet,
  Discount,
  DiscountsOutlet,
  Editor,
  Font,
  FontsOutlet,
  GiftCard,
  GiftCardsOutlet,
  HeldOrders,
  Home,
  HouseAccount,
  HouseAccountsOutlet,
  ItemType,
  ItemTypesOutlet,
  KdsTerminal,
  KdsTerminalOutlet,
  Logout,
  Loyalty,
  LoyaltyCustomer,
  LoyaltyProgram,
  LoyaltyProgramsOutlet,
  LoyaltyRoutes,
  LoyaltyTier,
  LoyaltyTiersOutlet,
  LoyaltyBonus,
  LoyaltyBonusesOutlet,
  LoyaltyThreshold,
  LoyaltyThresholdsOutlet,
  Marketing,
  MarketingRoutes,
  Menu,
  MenuCategoriesOutlet,
  MenuCategory,
  MenuCategoryItem,
  MenuCategoryItemsOutlet,
  MenuModifierGroup,
  MenuModifierGroupItem,
  MenuModifierGroupItemsOutlet,
  MenuModifierGroupsOutlet,
  Menus,
  MenusOutlet,
  MenusRoutes,
  Oauth2Client,
  Oauth2ClientsOutlet,
  Onboarding,
  OnboardingOutlet,
  OnboardingRoutes,
  Order,
  OrderMgmt,
  OrderMgmtStore,
  Orders,
  Pages,
  PagesRoutes,
  PagesAnnouncement,
  PagesAnnouncementRouting,
  PaymentTerminal,
  PaymentTerminalOutlet,
  PendingOrders,
  PosServer,
  PosServerOutlet,
  PosTerminal,
  PosTerminalOutlet,
  PrepStation,
  PrepStationsOutlet,
  Printer,
  PrinterOutlet,
  PromoCode,
  PromoCodesOutlet,
  Region,
  Reporting,
  ReportingRoutes,
  Reward,
  RewardsOutlet,
  RewardEvent,
  RewardEventOutlet,
  Scanner,
  ScannerOutlet,
  SegmentOutlet,
  Segment,
  Settings,
  SettingsRoutes,
  Store,
  StoreGroup,
  StoreRevenueCenter,
  StoreRevenueCentersOutlet,
  StoresOutlet,
  StoresRoutes,
  Super,
  SuperRoutes,
  Surcharge,
  SurchargesOutlet,
  Tax,
  TaxesOutlet,
  Tag,
  TagsOutlet,
  User,
  UsersOutlet,
  UnchargedOrders,
  UpcomingOrders,
  StoresOverview
} from 'pages'
import BulkOrderRefund from 'pages/Orders/BulkOrders/BulkOrderRefund'
import { selectIsSuper } from 'slices/authUser'
import { useAppSelector } from 'app/hooks'

const AppRoutes = () => {
  const isSuper = useAppSelector(selectIsSuper)

  return (
    <Routes>
      <Route path="orders">
        <Route path="order/*" element={<Order />} />
        <Route path="mgmt">
          <Route path=":storeId/*" element={<OrderMgmtStore />} />
          <Route index element={<OrderMgmt />} />
        </Route>
        <Route path="pending" element={<PendingOrders />} />
        <Route path="held" element={<HeldOrders />} />
        <Route path="search" element={<BulkOrders />} />
        <Route path="uncharged" element={<UnchargedOrders />} />
        <Route path="upcoming" element={<UpcomingOrders />} />
        <Route path=":orderId/*" element={<BulkOrder />} />
        <Route path=":orderId/refund" element={<BulkOrderRefund />} />
        {/* <Route path="*" element={<OrdersRoutes />} /> */}
        <Route index element={<Orders />} />
      </Route>
      <Route path="stores" element={<StoresOutlet />}>
        <Route
          path="stores/:storeId/revenue-centers/:revenueCenterId/*"
          element={<StoreRevenueCentersOutlet />}
        >
          <Route
            path="pos-servers/:posServerId/*"
            element={<PosServerOutlet />}
          >
            <Route path="*" element={<PosServer />} />
          </Route>
          <Route
            path="kiosk-terminals/:posTerminalId/*"
            element={<PosTerminalOutlet />}
          >
            <Route path="*" element={<PosTerminal />} />
          </Route>
          <Route
            path="kds-terminals/:kdsTerminalId/*"
            element={<KdsTerminalOutlet />}
          >
            <Route path="*" element={<KdsTerminal />} />
          </Route>
          <Route path="printers/:printerId/*" element={<PrinterOutlet />}>
            <Route path="*" element={<Printer />} />
          </Route>
          <Route path="scanners/:scannerId/*" element={<ScannerOutlet />}>
            <Route path="*" element={<Scanner />} />
          </Route>
          <Route
            path="payment-terminals/:paymentTerminalId/*"
            element={<PaymentTerminalOutlet />}
          >
            <Route path="*" element={<PaymentTerminal />} />
          </Route>
          <Route path="*" element={<StoreRevenueCenter />} />
        </Route>
        <Route
          path="stores/:storeId/prep-stations/:prepStationId/*"
          element={<PrepStationsOutlet />}
        >
          <Route path="*" element={<PrepStation />} />
        </Route>
        <Route path="stores/:storeId/*" element={<Store />} />
        <Route path="groups/:storeGroupId/*" element={<StoreGroup />} />
        <Route path="regions/:regionId/*" element={<Region />} />
        <Route path="*" element={<StoresRoutes />} />
        <Route index element={<StoresOverview />} />
      </Route>
      <Route path="menus" element={<MenusOutlet />}>
        <Route
          path="menus/:menuId/categories/:categoryId/*"
          element={<MenuCategoriesOutlet />}
        >
          <Route path="items/:itemId/*" element={<MenuCategoryItemsOutlet />}>
            <Route path="*" element={<MenuCategoryItem />} />
          </Route>
          <Route path="*" element={<MenuCategory />} />
        </Route>
        <Route
          path="menus/:menuId/modifier-groups/:modifierGroupId/*"
          element={<MenuModifierGroupsOutlet />}
        >
          <Route
            path="items/:itemId/*"
            element={<MenuModifierGroupItemsOutlet />}
          >
            <Route path="*" element={<MenuModifierGroupItem />} />
          </Route>
          <Route path="*" element={<MenuModifierGroup />} />
        </Route>
        <Route path="menus/:menuId/*" element={<Menu />} />
        <Route path="*" element={<MenusRoutes />} />
        <Route index element={<Menus />} />
      </Route>
      <Route path="design">
        <Route path="*" element={<DesignRoutes />} />
        <Route index element={<Design />} />
      </Route>
      <Route path="editor">
        <Route path="*" element={<Editor />} />
        <Route index element={<Navigate to="pages" replace />} />
      </Route>
      <Route path="pages">
        <Route path=":page/announcements">
          <Route index element={<PagesAnnouncementRouting />} />
          <Route path=":announcementPageId/*" element={<PagesAnnouncement />} />
        </Route>
        <Route path="*" element={<PagesRoutes />} />
        <Route index element={<Pages />} />
      </Route>
      <Route path="customers">
        <Route
          path="house-accounts/:houseAccountId/*"
          element={<HouseAccountsOutlet />}
        >
          <Route path="*" element={<HouseAccount />} />
        </Route>
        <Route path="mgmt/:customerId/*" element={<Customer />} />
        <Route path="*" element={<CustomersRoutes />} />
        <Route index element={<Customers />} />
      </Route>
      <Route path="loyalty">
        <Route
          path="loyalty-programs/:loyaltyProgramId/*"
          element={<LoyaltyProgramsOutlet />}
        >
          <Route path="tiers/:loyaltyTierId" element={<LoyaltyTiersOutlet />}>
            <Route path="*" element={<LoyaltyTier />} />
          </Route>
          <Route
            path="bonus-periods/:loyaltyBonusId"
            element={<LoyaltyBonusesOutlet />}
          >
            <Route path="*" element={<LoyaltyBonus />} />
          </Route>
          <Route
            path="thresholds/:loyaltyThresholdId"
            element={<LoyaltyThresholdsOutlet />}
          >
            <Route path="*" element={<LoyaltyThreshold />} />
          </Route>
          <Route
            path="customers/:loyaltyCustomerId"
            element={<LoyaltyCustomer />}
          />
          <Route path="*" element={<LoyaltyProgram />} />
        </Route>
        <Route path="promo-codes/:promoCodeId/*" element={<PromoCodesOutlet />}>
          <Route path="*" element={<PromoCode />} />
        </Route>
        <Route path="rewards/:rewardId/*" element={<RewardsOutlet />}>
          <Route path="*" element={<Reward />} />
        </Route>
        <Route
          path="reward-events/:campaignId/*"
          element={<RewardEventOutlet />}
        >
          <Route path="*" element={<RewardEvent />} />
        </Route>
        <Route path="deals/:dealId/*" element={<DealsOutlet />}>
          <Route path="*" element={<Deal />} />
        </Route>
        <Route
          path="gift-cards/card/:giftCardId/*"
          element={<GiftCardsOutlet />}
        >
          <Route path="*" element={<GiftCard />} />
        </Route>
        <Route path="*" element={<LoyaltyRoutes />} />
        <Route index element={<Loyalty />} />
      </Route>
      <Route path="marketing">
        <Route path="campaigns/:campaignId/*" element={<CampaignOutlet />}>
          <Route path="*" element={<Campaign />} />
        </Route>
        {isSuper && (
          <Route
            path="campaign-templates/:campaignId/*"
            element={<CampaignOutlet />}
          >
            <Route path="*" element={<Campaign isTemplate={true} />} />
          </Route>
        )}
        <Route path="segments/:segmentId/*" element={<SegmentOutlet />}>
          <Route path="*" element={<Segment />} />
        </Route>
        <Route path="*" element={<MarketingRoutes />} />
        <Route index element={<Marketing />} />
      </Route>
      <Route path="reporting">
        <Route path="*" element={<ReportingRoutes />} />
        <Route index element={<Reporting />} />
      </Route>
      <Route path="settings">
        <Route path="users/manage/:userId/*" element={<UsersOutlet />}>
          <Route path="*" element={<User />} />
        </Route>
        <Route
          path="misc/item-types/:itemTypeId/*"
          element={<ItemTypesOutlet />}
        >
          <Route path="*" element={<ItemType />} />
        </Route>
        <Route path="ops/discounts/:discountId/*" element={<DiscountsOutlet />}>
          <Route path="*" element={<Discount />} />
        </Route>
        <Route
          path="ops/surcharges/:surchargeId/*"
          element={<SurchargesOutlet />}
        >
          <Route path="*" element={<Surcharge />} />
        </Route>
        <Route path="ux/fonts/:fontId/*" element={<FontsOutlet />}>
          <Route path="*" element={<Font />} />
        </Route>
        <Route path="misc/tags/:tagId/*" element={<TagsOutlet />}>
          <Route path="*" element={<Tag />} />
        </Route>
        <Route
          path="misc/allergens/:allergenId/*"
          element={<AllergensOutlet />}
        >
          <Route path="*" element={<Allergen />} />
        </Route>
        <Route path="ops/taxes/:taxId/*" element={<TaxesOutlet />}>
          <Route path="*" element={<Tax />} />
        </Route>
        <Route
          path="misc/clients/:oauth2ClientId/*"
          element={<Oauth2ClientsOutlet />}
        >
          <Route path="*" element={<Oauth2Client />} />
        </Route>
        <Route
          path="comms/announcements/:announcementId/*"
          element={<AnnouncementsOutlet />}
        >
          <Route path="*" element={<Announcement />} />
        </Route>
        <Route path="*" element={<SettingsRoutes />} />
        <Route index element={<Settings />} />
      </Route>
      <Route path="onboarding" element={<OnboardingOutlet />}>
        <Route path="*" element={<OnboardingRoutes />} />
        <Route index element={<Onboarding />} />
      </Route>
      <Route path="super">
        <Route path="*" element={<SuperRoutes />} />
        <Route index element={<Super />} />
      </Route>
      <Route path="logout" element={<Logout />} />
      <Route index element={<Home />} />
    </Routes>
  )
}

export default AppRoutes

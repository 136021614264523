const DotsX = ({
  size = 20,
  highlight,
  opacity = 0.25
}: {
  size?: number
  highlight?: number
  opacity?: number
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        opacity={!highlight || highlight === 4 ? 1 : opacity}
        d="M8.45602 17.0796C7.92858 17.607 7.21321 17.9033 6.46729 17.9033C5.72136 17.9033 5.00599 17.607 4.47855 17.0796C3.9511 16.5521 3.65479 15.8367 3.65479 15.0908C3.65479 14.3449 3.9511 13.6295 4.47855 13.1021C5.00599 12.5746 5.72136 12.2783 6.46729 12.2783C7.21321 12.2783 7.92858 12.5746 8.45602 13.1021C8.98347 13.6295 9.27979 14.3449 9.27979 15.0908C9.27979 15.8367 8.98347 16.5521 8.45602 17.0796Z"
      />
      <path
        fill="currentColor"
        opacity={!highlight || highlight === 5 ? 1 : opacity}
        d="M17.831 17.0796C18.3585 16.5521 18.6548 15.8367 18.6548 15.0908C18.6548 14.3449 18.3585 13.6295 17.831 13.1021C17.3036 12.5746 16.5882 12.2783 15.8423 12.2783C15.0964 12.2783 14.381 12.5746 13.8535 13.1021C13.3261 13.6295 13.0298 14.3449 13.0298 15.0908C13.0298 15.8367 13.3261 16.5521 13.8535 17.0796C14.381 17.607 15.0964 17.9033 15.8423 17.9033C16.5882 17.9033 17.3036 17.607 17.831 17.0796Z"
      />
      <path
        fill="currentColor"
        opacity={!highlight || highlight === 6 ? 1 : opacity}
        d="M27.206 17.0796C27.7335 16.5521 28.0298 15.8367 28.0298 15.0908C28.0298 14.3449 27.7335 13.6295 27.206 13.1021C26.6786 12.5746 25.9632 12.2783 25.2173 12.2783C24.4714 12.2783 23.756 12.5746 23.2285 13.1021C22.7011 13.6295 22.4048 14.3449 22.4048 15.0908C22.4048 15.8367 22.7011 16.5521 23.2285 17.0796C23.756 17.607 24.4714 17.9033 25.2173 17.9033C25.9632 17.9033 26.6786 17.607 27.206 17.0796Z"
      />
    </svg>
  )
}

export default DotsX

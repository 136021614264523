import { Button, TextBody } from 'components'
import { ModalWindow } from 'types'
import { useAppDispatch } from 'app/hooks'
import { ModalFooter, ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'

interface CampaignTemplateArchiveModalProps {
  args: {
    isTemplate: boolean
    onSubmit: () => void
  }
  modalWindow: ModalWindow
}

const CampaignTemplateArchive = ({
  args: { isTemplate, onSubmit },
  modalWindow
}: CampaignTemplateArchiveModalProps) => {
  const dispatch = useAppDispatch()

  const handleArchive = () => {
    onSubmit()
    dispatch(closeModal())
  }

  const handleCancel = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Deactivate Template" />
      <TextBody as="p" size="large" style={{ marginTop: '1rem' }}>
        {`Are you sure you want to deactivate this campaign template? It will be hidden
        elsewhere in the application.`}
      </TextBody>
      <ModalFooter>
        <Button color="secondary" size="medium" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" size="medium" onClick={() => handleArchive()}>
          Deactivate
        </Button>
      </ModalFooter>
    </>
  )
}

export default CampaignTemplateArchive

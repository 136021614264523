import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { CampaignContent } from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'

export interface CampaignContentState {
  data: CampaignContent | null
  loading: RequestStatus
  testing: RequestStatus
  autoSavedAt: string | null
  error: RequestError
}

const initialState: CampaignContentState = {
  data: null,
  loading: RequestStatus.Idle,
  testing: RequestStatus.Idle,
  autoSavedAt: null,
  error: null
}

export type UpdateCampaignContentParams = Pick<
  CampaignContent,
  | 'campaign_content_id'
  | 'campaign_id'
  | 'channel'
  | 'subject'
  | 'sender_email'
  | 'sender_name'
  | 'editor_config'
  | 'preview_text'
  | 'content_type'
  | 'body'
  | 'reply_to'
  | 'is_locked'
>
export type CreateCampaignContentParams = Omit<
  UpdateCampaignContentParams,
  'campaign_content_id'
>
export type PatchCampaignContentParams = Partial<
  Pick<
    CampaignContent,
    | 'subject'
    | 'sender_email'
    | 'sender_name'
    | 'editor_config'
    | 'preview_text'
    | 'title'
    | 'description'
    | 'body'
    | 'reply_to'
    | 'is_locked'
  >
>

export interface SendTestParams {
  campaign_content_id: number
  recipients: string[]
}

export const createCampaignContent = createAsyncThunk<
  CampaignContent,
  CreateCampaignContentParams,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign-content',
  async (
    campaignContent,
    { getState, dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaign-content`
      const resp = (await api?.request(endpoint, 'POST', {
        campaign_id: campaignContent.campaign_id,
        channel: campaignContent.channel,
        body: campaignContent.body,
        subject: campaignContent.subject || '',
        sender_name: campaignContent.sender_name,
        sender_email: campaignContent.sender_email,
        preview_text: campaignContent.preview_text,
        editor_config: campaignContent.editor_config,
        content_type: campaignContent.content_type,
        reply_to: campaignContent.reply_to,
        is_locked: campaignContent.is_locked || false
      })) as CampaignContent
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCampaignContent = createAsyncThunk<
  CampaignContent,
  UpdateCampaignContentParams,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign-content/updateCampaignContent',
  async ({ campaign_content_id, ...data }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaign-content/${campaign_content_id}`
      const resp = (await api?.request(endpoint, 'PUT', {
        campaign_id: data.campaign_id,
        body: data.body,
        channel: data.channel,
        content_type: data.content_type,
        editor_config: data.editor_config,
        preview_text: data.preview_text,
        sender_email: data.sender_email,
        sender_name: data.sender_name,
        subject: data.subject,
        reply_to: data.reply_to,
        is_locked: data.is_locked
      })) as CampaignContent
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const patchCampaignContent = createAsyncThunk<
  CampaignContent | null,
  PatchCampaignContentParams,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign-content/patchCampaignContent',
  async (patch, { getState, rejectWithValue }) => {
    try {
      const state = getState()
      const api = state.authUser.api
      const content = state.campaignContent.data
      if (!content) return null
      const endpoint = `campaign-content/${content.campaign_content_id}`
      const resp = (await api?.request(endpoint, 'PUT', {
        campaign_id: content.campaign_id,
        channel: content.channel,
        body: patch.body ?? content.body,
        content_type: content.content_type,
        editor_config: patch.editor_config ?? content.editor_config,
        preview_text: patch.preview_text ?? content.preview_text,
        sender_email: patch.sender_email ?? content.sender_email,
        sender_name: patch.sender_name ?? content.sender_name,
        subject: patch.subject ?? content.subject,
        reply_to: patch.reply_to ?? content.reply_to,
        is_locked: patch.is_locked ?? content.is_locked
      })) as CampaignContent
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const publishCampaignContent = createAsyncThunk<
  CampaignContent,
  Pick<CampaignContent, 'campaign_content_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign-content/publishCampaignContent',
  async (
    { campaign_content_id },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaign-content/${campaign_content_id}/publish`
      const resp = (await api?.request(endpoint, 'POST')) as CampaignContent
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const autoSaveCampaignContent = createAsyncThunk<
  CampaignContent | null,
  Pick<CampaignContent, 'editor_config'>,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign-content/autoSaveCampaign',
  async (patch, { getState, rejectWithValue }) => {
    try {
      const state = getState()
      const api = state.authUser.api
      const content = state.campaignContent.data
      if (!content) return null
      const endpoint = `campaign-content/${content.campaign_content_id}`
      const resp = (await api?.request(endpoint, 'PUT', {
        campaign_id: content.campaign_id,
        channel: content.channel,
        body: content.body,
        is_locked: content.is_locked,
        content_type: content.content_type,
        editor_config: patch.editor_config,
        preview_text: content.preview_text,
        sender_email: content.sender_email,
        sender_name: content.sender_name,
        subject: content.subject,
        reply_to: content.reply_to
      })) as CampaignContent
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const testCampaignContent = createAsyncThunk<
  void,
  SendTestParams,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign-content/testCampaignContent',
  async (
    { campaign_content_id, recipients },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaign-content/${campaign_content_id}/test`
      await api?.request(endpoint, 'POST', { recipients })
      return fulfillWithValue(undefined)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchCampaignContent = createAsyncThunk<
  CampaignContent,
  Pick<CampaignContent, 'campaign_content_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign-content/fetchCampaignContent',
  async (
    { campaign_content_id },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const queryParams = makeQueryParams({ with_related: true })
      const api = getState().authUser.api
      const endpoint = `campaign-content/${campaign_content_id}${queryParams}`
      const resp = (await api?.request(endpoint)) as CampaignContent
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteCampaignContent = createAsyncThunk<
  void,
  Pick<CampaignContent, 'campaign_content_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign-content/deleteCampaignContent',
  async ({ campaign_content_id }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaign-content/${campaign_content_id}`
      await api?.request(endpoint, 'DELETE')
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const campaignContentSlice = createSlice({
  name: 'campaign-content',
  initialState,
  reducers: {
    resetCampaignContent: () => initialState,
    setCampaignContent: (
      state,
      action: PayloadAction<CampaignContent | null>
    ) => {
      state.data = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(createCampaignContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createCampaignContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createCampaignContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCampaignContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateCampaignContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateCampaignContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(patchCampaignContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(patchCampaignContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(patchCampaignContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(autoSaveCampaignContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(autoSaveCampaignContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      if (payload) state.autoSavedAt = payload.updated_at
      state.error = null
    })
    builder.addCase(autoSaveCampaignContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
    })
    builder.addCase(fetchCampaignContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCampaignContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchCampaignContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteCampaignContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteCampaignContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = null
      state.error = null
    })
    builder.addCase(deleteCampaignContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(publishCampaignContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(publishCampaignContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.error = null
    })
    builder.addCase(publishCampaignContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(testCampaignContent.pending, state => {
      state.testing = RequestStatus.Pending
    })
    builder.addCase(testCampaignContent.fulfilled, (state, { payload }) => {
      state.testing = RequestStatus.Succeeded
      state.error = null
    })
    builder.addCase(testCampaignContent.rejected, (state, { payload }) => {
      state.testing = RequestStatus.Failed
      state.error = payload
    })
  }
})

export const { resetCampaignContent, setCampaignContent } =
  campaignContentSlice.actions

export const selectCampaignContent = (state: RootState) => state.campaignContent

export default campaignContentSlice.reducer

import styled from '@emotion/styled'

export const ButtonTabsView = styled.div<{ error?: boolean }>`
  button {
    // min-width: 10rem;
    border-radius: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transition: all 0.15s ease;
    border-left-width: 0;
    color: ${props => props.theme.colors.text.secondary};
    border-color: ${props => props.theme.colors.border.primary};
    border-color: ${props =>
      props.error
        ? props.theme.colors.error.color
        : props.theme.colors.border.primary};

    &:first-of-type {
      border-left-width: 0.1rem;
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
    }

    &:last-of-type {
      border-top-right-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }

    &.-selected,
    &:enabled:hover {
      opacity: 1;
      font-weight: 700;
      color: ${props => props.theme.colors.text.primary};
      border-color: ${props => props.theme.colors.border.primary};
      background-color: ${props => props.theme.colors.border.primary};
    }
  }
`

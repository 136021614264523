const Underline = ({ size = 9 }: { size?: number }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 13 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.86786 18.4409V6.4326H0.393795V4.59261H11.4918V6.4326H7.01774V18.4409H4.86786Z"
        fill="currentColor"
      />
      <line
        x1="0.103516"
        y1="21.9277"
        x2="12.2087"
        y2="21.9277"
        stroke="currentColor"
        strokeWidth="1.21052"
      />
    </svg>
  )
}

export default Underline

import styled from '@emotion/styled'

export const IPhoneCondensedContainer = styled.div`
  position: relative;
  z-index: 1;
  min-height: 24rem;
  width: 100%;
`

export const IPhoneFrame = styled.div`
  background-color: #000;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
`

export const Screen = styled.div`
  background: linear-gradient(to right, #003354, #1e96e4);
  border: 5px solid #000;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  min-height: 24rem;
  width: 100%;
  overflow: hidden;
`

export const Notch = styled.div`
  background-color: #000;
  border-radius: 13px;
  height: 20px;
  width: 124px;
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
`

export const IPhoneLockScreenNotification = styled.div`
  position: absolute;
  top: 10rem;
  margin-left: 1.1rem;
  background-color: #ffffffe3;
  font-size: 14px;
  border-radius: 23px;
  width: calc(100% - 2rem);
  min-height: 7rem;
  display: grid;
  grid-template-columns: 1fr 3fr auto;
  align-items: center;
  padding: 1rem 1.5rem 1rem 1rem;

  & :nth-child(3) {
    align-self: start;
  }
  @media (max-width: 1024px) {
    gap: 0.3rem;
  }
`

export const IPhoneLockScreenBrandLogo = styled.div`
  width: 4rem;
  height: 4rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${props => props.theme.borderRadius.small};
  }
`

export const IPhoneLockScreenContent = styled.div`
  padding: 0 0.3rem;
  text-align: left;
`

export const IPhoneLockScreenTitle = styled.h3`
  color: #000;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  word-wrap: break-word;
`

export const IPhoneLockScreenSubtitle = styled.p`
  color: #000;
  font-size: 12px;
  margin: 0.3rem 0;
  word-wrap: break-word;
`

export const IPhoneLockScreenMessage = styled.div`
  color: #000;
  font-size: 12px;
  margin-top: 0.5rem;
  word-wrap: break-word;
  white-space: pre-wrap;
`

export const IPhoneLockScreenContentTime = styled.p`
  color: #929292;
  font-size: 12px;
  margin: 0;
  text-align: right;

  @media (max-width: 480px) {
    text-align: center;
  }
`

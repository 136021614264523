import { Surcharge, SurchargeServiceType } from 'types'

const baseServiceTypes: SurchargeServiceType[] = ['ALL', 'DELIVERY']

export const filterBaseSurcharges = (surcharge: Surcharge) => {
  const { surcharge_type, service_type, is_active } = surcharge
  return (
    surcharge_type === 'DOLLAR' &&
    baseServiceTypes.includes(service_type) &&
    is_active
  )
}

export const filterVariableDistanceSurcharges = (surcharge: Surcharge) => {
  const { surcharge_type, service_type, is_active } = surcharge
  return (
    surcharge_type === 'DISTANCE' &&
    baseServiceTypes.includes(service_type) &&
    is_active
  )
}

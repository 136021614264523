import { Categories, DiscountItemCategory } from 'types'
import { Button, Input } from 'components'

import DiscountedItemsGroup from './DiscountedItemsGroup'
import {
  DiscountedItemButtonWrap,
  DiscountedItemsContainer,
  DiscountedItemsGroups
} from '../DiscountedAndRequiredItems.styled'

const DiscountedItems = ({
  discountedQuantity,
  requiredQuantity,
  categoriesData,
  discountedIds,
  requiredIds,
  showDelete,
  submit,
  setDiscountedQuantity,
  setRequiredQuantity,
  updateDiscountedValues,
  updateRequiredValues,
  handleDelete,
  updateAllRequiredValues,
  updateAllDiscountedValues
}: {
  discountedQuantity?: number
  requiredQuantity?: number
  categoriesData?: Categories | null
  discountedIds?: DiscountItemCategory[]
  requiredIds?: DiscountItemCategory[]
  showDelete?: boolean
  submit?: () => void
  setDiscountedQuantity?: (discountedQuantity: number | undefined) => void
  setRequiredQuantity?: (requiredQuantity: number) => void
  updateDiscountedValues?: (category_id: number, id: number) => void
  updateRequiredValues?: (category_id: number, id: number) => void
  updateAllDiscountedValues?: (category_id: number, ids: number[]) => void
  updateAllRequiredValues?: (category_id: number, ids: number[]) => void
  handleDelete?: () => void
}) => {
  return (
    <>
      <DiscountedItemsGroups>
        <DiscountedItemsContainer>
          {setDiscountedQuantity && (
            <Input
              type="number"
              name="discounted_quantity"
              fieldType="Input"
              label="Discounted Quantity"
              value={discountedQuantity}
              min={0}
              onChange={evt =>
                setDiscountedQuantity(parseInt(evt.target.value))
              }
            />
          )}
          <DiscountedItemsGroup
            selectedIds={discountedIds}
            updateValues={updateDiscountedValues}
            updateAllValues={updateAllDiscountedValues}
            itemValues={categoriesData}
            itemTitle="Discounted Items"
          />
        </DiscountedItemsContainer>
        <DiscountedItemsContainer>
          {setRequiredQuantity && (
            <Input
              type="number"
              name="required_quantity"
              fieldType="Input"
              label="Required Quantity"
              value={requiredQuantity}
              min={0}
              onChange={evt => setRequiredQuantity(parseInt(evt.target.value))}
            />
          )}
          <DiscountedItemsGroup
            selectedIds={requiredIds}
            updateValues={updateRequiredValues}
            updateAllValues={updateAllRequiredValues}
            itemValues={categoriesData}
            itemTitle="Required Items"
          />
        </DiscountedItemsContainer>
      </DiscountedItemsGroups>
      <DiscountedItemButtonWrap>
        <Button onClick={submit} size="medium">
          Submit
        </Button>
        {showDelete && (
          <Button color="delete" type="button" onClick={handleDelete}>
            Delete
          </Button>
        )}
      </DiscountedItemButtonWrap>
    </>
  )
}

export default DiscountedItems

const CornerBottomLeft = ({ size = 9 }: { size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 8 8"
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        d="M0 3.5C0 5.985 2.015 8 4.5 8H8V7H4.5C2.567 7 1 5.433 1 3.5V0H0v3.5z"
      />
    </svg>
  )
}

export default CornerBottomLeft

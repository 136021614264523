import { Autocomplete, ThemeProvider, createTheme } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { AutoCompleteDropdownOption, AutoCompleteDropdownOptions } from 'types'
import { selectGlobal } from 'slices/global'
import Label from '../Label'
import {
  AutoCompleteWrapper,
  OptionText,
  ReadOnlyChip,
  StyledChip,
  StyledTextField
} from './AutoComplete.styled'
import { useMemo } from 'react'

interface AutoCompleteProps {
  label?: string
  parenthetical?: string
  tooltip?: string
  name?: string
  value?: AutoCompleteDropdownOptions
  options: AutoCompleteDropdownOptions
  fixedOptions?: AutoCompleteDropdownOptions
  placeholder?: string
  required?: boolean
  disabled?: boolean
  nullable?: boolean
  groupBy?: (option: AutoCompleteDropdownOption) => string
  onChange?: (value: AutoCompleteDropdownOptions, reason?: string) => void
}

const AutoComplete = ({
  label,
  parenthetical,
  tooltip,
  value,
  options,
  fixedOptions,
  placeholder,
  required,
  groupBy,
  onChange
}: AutoCompleteProps) => {
  const { colorScheme } = useAppSelector(selectGlobal)
  const currentTheme: 'light' | 'dark' =
    colorScheme === 'DARK' ? 'dark' : 'light'

  const theme = createTheme({
    palette: {
      mode: currentTheme
    }
  })

  const handleSelectChange = (
    selectedOptions: AutoCompleteDropdownOptions,
    reason: string
  ) => {
    const newValue = selectedOptions.filter(
      option => !fixedOptions?.some(fixed => fixed.value === option.value)
    )
    onChange && onChange(newValue, reason)
  }

  const selectedOptions = useMemo(() => {
    return [
      ...(fixedOptions ?? []),
      ...(value ?? []).filter(
        option =>
          !(fixedOptions ?? []).some(fixed => fixed.value === option.value)
      )
    ]
  }, [value, fixedOptions])

  return (
    <>
      <Label
        label={label}
        parenthetical={parenthetical}
        required={required}
        tooltip={tooltip}
      >
        <ThemeProvider theme={theme}>
          <AutoCompleteWrapper>
            <Autocomplete
              multiple
              autoComplete={true}
              options={options}
              value={selectedOptions}
              getOptionLabel={option => option.text}
              filterSelectedOptions
              groupBy={groupBy}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderOption={(props, option, index) => (
                <li {...props} key={option.value}>
                  <OptionText>{option.text}</OptionText>
                </li>
              )}
              renderTags={(tagValue, getTagProps) => [
                ...(fixedOptions ?? []).map((option, index) => (
                  <ReadOnlyChip
                    size="small"
                    label={option.text}
                    {...getTagProps({ index })}
                  />
                )),
                ...tagValue
                  .filter(
                    option =>
                      !fixedOptions?.some(fixed => fixed.value === option.value)
                  )
                  .map((option, index) => (
                    <StyledChip
                      size="small"
                      label={option.text}
                      {...getTagProps({
                        index: index + (fixedOptions?.length ?? 0)
                      })}
                    />
                  ))
              ]}
              renderInput={params =>
                params ? (
                  <StyledTextField
                    {...params}
                    size="small"
                    placeholder={placeholder}
                    sx={{ input: { fontSize: '1.4rem', height: '2.5rem' } }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: null
                    }}
                  />
                ) : null
              }
              onChange={(_event, options, reason) => {
                handleSelectChange(options, reason)
              }}
            />
          </AutoCompleteWrapper>
        </ThemeProvider>
      </Label>
    </>
  )
}

export default AutoComplete

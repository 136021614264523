import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'

export const Section = styled.div`
  margin: 2.4rem 0;
`
export const SectionTitle = styled(TextHeading)`
  display: block;
  margin: 0.5rem 0;
`

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`

export const DeliveryProvidersCard = styled.div`
  background-color: ${props => props.theme.colors.background.secondary};
  border-radius: 8px;
  padding: 1.6rem 0.8rem;
  margin: 1.6rem 0;
`

export const DeliveryProvidersContainer = styled.div`
  position: relative;
`

export const DeliveryRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  position: relative;
  top: auto !important;
  left: auto !important;
`

export const DeliveryProvider = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  height: 3.2rem;
  max-width: 50rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`

export const DeliveryProviderIndex = styled(TextBody)`
  margin-right: 0.6rem;
  width: 1rem;
`
export const DragHandle = styled.span`
  margin-top: 0.5rem;
  cursor: grab;
`

export const List = styled.div`
  background-color: ${props => props.theme.colors.background.secondary};
  padding: 1rem;
  border-radius: 5px;
  position: relative;
`

export const Name = styled(TextBody)`
  flex: 1;
`

import {
  DeliveryProvider,
  Distance,
  DistanceDeliveryTier,
  DistanceDeliveryTiers,
  TiersFormData
} from 'types'
import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Button, Input } from 'components'
import { capitalize, handleError, RequestError } from 'utils'
import TextHeading from 'components/TextHeading'
import ErrMsg from 'components/ErrMsg'
import { SectionHeader } from './AdvancedDeliveryTiers'
import isEqual from 'react-fast-compare'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ProviderTiers = styled.div`
  background-color: ${props => props.theme.colors.background.secondary};
  border-radius: 4px;
  padding: 1rem;
  margin: 1.6rem 0;
`
export const SectionTitle = styled(TextHeading)`
  display: block;
  margin: 0.5rem 0;
`

export const StyledError = styled(ErrMsg)`
  margin: 0 0 2.8rem;
`

export const Header = styled.div<{ readOnly: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-right: ${props => (props.readOnly ? 0 : '90px')};
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid #606589;
  column-gap: 2rem;
`

export const HeaderCell = styled.strong`
  display: flex;
  flex-direction: row;
  flex: 1;
  font-size: 1.4rem;
  color: #d8dff3;
`

export const Rows = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 2rem;
  flex: 1;
`

export const RowActions = styled.div`
  align-self: flex-start;
  padding-top: 0.3rem;
`

export const InputContainer = styled.div`
  flex: 1;
`

export const Inputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 2rem;
  flex: 1;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`

const TiersForm = ({
  initialValues,
  isLoading,
  onSubmit,
  surchargeId,
  error,
  storeId = null,
  locationId = null,
  deliveryProvider,
  canEdit,
  setCanEdit
}: {
  initialValues: TiersFormData
  isLoading: boolean
  onSubmit: (
    values: DistanceDeliveryTiers,
    provider: DeliveryProvider
  ) => Promise<void>
  surchargeId: number
  storeId?: number | null
  locationId?: number | null
  error?: RequestError
  deliveryProvider: DeliveryProvider
  canEdit: DeliveryProvider | 'all'
  setCanEdit: (d: DeliveryProvider | 'all') => void
}) => {
  const [readOnly, setReadOnly] = useState<boolean>(true)
  const [values, setValues] = useState<TiersFormData>(initialValues)
  const [errors, setErrors] = useState<Record<string, unknown>>({})
  const [frErrors, setFrErrors] = useState<
    Record<number, Record<string, string>>
  >({})

  const errMsg = errors ? (errors.form as string) : null

  useEffect(() => {
    if (isLoading) return
    setErrors(error ? handleError(error) : {})
  }, [isLoading, error])

  useEffect(() => {
    setValues(initialValues)
  }, [initialValues])

  useEffect(() => {
    if (!readOnly) return
    setValues(initialValues)
  }, [readOnly, initialValues])

  const deleteRow = (indexToDelete: number) => {
    setValues(prevState =>
      prevState.filter((_, index) => index !== indexToDelete)
    )
  }

  const addRow = () => {
    setValues(prevState => {
      if (prevState.length === 0) return [tierDefault]
      const lastTier = prevState[prevState.length - 1]
      const min_distance = lastTier.max_distance
        ? ((Number(lastTier.max_distance) + 0.01).toFixed(2) as Distance)
        : undefined
      const max_distance = (100).toFixed(2) as Distance
      const newRowTier = { ...tierDefault, min_distance, max_distance }
      return [...prevState, newRowTier]
    })
  }

  const handleOnInputChange =
    (name: string, index: number) =>
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const value = event.target.value

      setValues(prevState => {
        const newValues = [...prevState]
        newValues[index] = { ...newValues[index], [name]: value }
        return newValues
      })
    }

  const handleOnSubmit = async () => {
    const validationErrors = validateTiers(values)

    if (Object.keys(validationErrors).length > 0) {
      setFrErrors(validationErrors)
      return
    } else {
      setFrErrors({})
    }

    const data = values.map(tier => ({
      ...tier,
      ...tierDefault,
      delivery_provider: deliveryProvider,
      surcharge_id: surchargeId,
      store_id: storeId || null,
      location_id: locationId || null
    }))

    await onSubmit(data as DistanceDeliveryTiers, deliveryProvider)
    setReadOnly(true)
  }
  const handleEdit = () => {
    setReadOnly(prevState => !prevState)
    setFrErrors({})
    setCanEdit(readOnly ? deliveryProvider : 'all')
  }
  const isSaveButtonDisabled = isEqual(initialValues, values)
  const renderRow = (row: Partial<DistanceDeliveryTier>, index: number) => {
    return (
      <Row key={index}>
        <Inputs>
          <InputContainer>
            <Input
              readOnly={readOnly}
              fieldType="Input"
              name="min_distance"
              type="text"
              value={row.min_distance ?? ''}
              onChange={handleOnInputChange('min_distance', index)}
              required
              disabled={isLoading}
              error={frErrors[index]?.min_distance}
            />
          </InputContainer>
          <InputContainer>
            <Input
              readOnly={readOnly}
              fieldType="Input"
              name="max_distance"
              type="text"
              value={row.max_distance ?? ''}
              onChange={handleOnInputChange('max_distance', index)}
              required
              disabled={isLoading}
              error={frErrors[index]?.max_distance}
            />
          </InputContainer>
          <InputContainer>
            <Input
              readOnly={readOnly}
              fieldType="Input"
              name="amount"
              type="text"
              value={row.amount ?? ''}
              onChange={handleOnInputChange('amount', index)}
              required
              disabled={isLoading}
              error={frErrors[index]?.amount}
            />
          </InputContainer>
        </Inputs>
        {!readOnly && (
          <RowActions>
            <Button color="delete" onClick={() => deleteRow(index)}>
              Delete
            </Button>
          </RowActions>
        )}
      </Row>
    )
  }

  return (
    <Container>
      <SectionHeader>
        <SectionTitle size="h5">
          {deliveryProvider === 'NONE'
            ? 'Self Delivery'
            : `Delivery Provider - ${capitalize(deliveryProvider)}`}
        </SectionTitle>
        {(canEdit === 'all' || canEdit === deliveryProvider) && (
          <Button color="secondary" onClick={handleEdit}>
            {readOnly ? 'Edit' : 'Cancel'}
          </Button>
        )}
      </SectionHeader>
      {errMsg && <StyledError errMsg={errMsg} />}
      <ProviderTiers>
        <Header readOnly={readOnly}>
          <HeaderCell>Minimum Distance (mi)</HeaderCell>
          <HeaderCell>Maximum Distance (mi)</HeaderCell>
          <HeaderCell>Amount Charged ($)</HeaderCell>
        </Header>
        <Rows>{values.map(renderRow)}</Rows>
        {!readOnly && (
          <Footer>
            <Button disabled={isLoading} color="secondary" onClick={addRow}>
              Add New Row
            </Button>
            <Button
              disabled={isLoading || isSaveButtonDisabled}
              color="primary"
              onClick={handleOnSubmit}
            >
              Save
            </Button>
          </Footer>
        )}
      </ProviderTiers>
    </Container>
  )
}

const tierDefault: Omit<
  DistanceDeliveryTier,
  | 'surcharge_id'
  | 'amount'
  | 'min_distance'
  | 'max_distance'
  | 'distance_delivery_tier_id'
> = {
  delivery_provider: 'NONE',
  location_id: null,
  store_id: null
}

const validateTiers = (values: TiersFormData) => {
  const errors: Record<number, Record<string, string>> = {}

  values.forEach((tier, index) => {
    const rowErrors: Record<string, string> = {}

    if (!tier.min_distance) rowErrors.min_distance = 'Field is required.'
    if (!tier.max_distance) rowErrors.max_distance = 'Field is required.'
    if (!tier.amount) rowErrors.amount = 'Field is required.'

    if (
      tier.min_distance &&
      (!/^\d+(\.\d+)?$/.test(tier.min_distance) ||
        Number(tier.min_distance) < 0)
    )
      rowErrors.min_distance =
        'Minimum distance must be a number greater or equal 0.'
    if (
      tier.max_distance &&
      (!/^\d+(\.\d+)?$/.test(tier.max_distance) ||
        Number(tier.max_distance) < 0)
    )
      rowErrors.max_distance =
        'Maximum distance must be a number greater or equal 0.'
    if (
      tier.amount &&
      (!/^\d+(\.\d+)?$/.test(tier.amount) || Number(tier.amount) < 0)
    )
      rowErrors.amount = 'Amount must be a number greater or equal 0.'

    if (index > 0) {
      const prevTier = values[index - 1]

      if (
        prevTier.max_distance &&
        tier.min_distance &&
        getDiff(tier.min_distance, prevTier.max_distance) !== 0.01
      ) {
        rowErrors.min_distance = 'Invalid range.'
      }
    }
    if (Number(tier.min_distance) > Number(tier.max_distance)) {
      rowErrors.max_distance = 'Must be higher than minimum distance.'
    }
    if (Object.keys(rowErrors).length > 0) {
      errors[index] = rowErrors
    }
  })

  return errors
}

const getDiff = (num1: string, num2: string, precision = 10) => {
  const difference = Math.abs(Number(num1) - Number(num2))
  return Number(difference.toFixed(precision))
}

export default TiersForm

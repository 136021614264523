const CornerBottomRight = ({ size = 9 }: { size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 8 8"
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        d="M8 3.5C8 5.985 5.985 8 3.5 8H0V7h3.5C5.433 7 7 5.433 7 3.5V0h1v3.5z"
      />
    </svg>
  )
}

export default CornerBottomRight

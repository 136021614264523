import { FormFieldType, ModalWindow, PosTerminal, PosTerminals } from 'types'
import { ModalHeader } from 'components/Modal'
import Form from 'components/Forms/Form'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  fetchRevenueCenterPosSettings,
  selectRevenueCenterPosSettings,
  upsertRevenueCenterPosSettings
} from 'slices/revenueCenterPosSettings'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { closeModal } from 'slices/modal'
import { selectPosTerminal, updatePosTerminal } from 'slices/posTerminal'

interface DisableKioskArgs {
  terminals: PosTerminals
  revenueCenterId: string | number
}
const DIABLE_WHILE_CLOSED_DATE = '2007-07-07T07:07:07+00:00'

type KIOSK_STATUS =
  | 'enable_indefinitely'
  | 'disable_indefinitely'
  | 'disable_until'
  | 'disable_while_closed'

interface KioskStatusOption {
  text: string
  value: KIOSK_STATUS
}

interface DisableKioskFormData {
  kiosk_status: KIOSK_STATUS | null
  disable_kiosk_message: string | null
  disable_until: string | null
}

const DisableKiosk = ({
  args,
  modalWindow
}: {
  args: DisableKioskArgs
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [terminals, setTerminals] = useState<PosTerminals>(args.terminals)
  const {
    data: kioskSettingsData,
    error,
    loading
  } = useAppSelector(selectRevenueCenterPosSettings)
  const { error: editPosTerminalError, loading: editPostTerminalLoading } =
    useAppSelector(selectPosTerminal)
  const [fields, setFields] = useState<FormFieldType[]>(disableKioskFields)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const isLoading =
    loading === 'pending' || editPostTerminalLoading === 'pending'
  const { disable_kiosk_message } = kioskSettingsData || {
    disable_kiosk_message: null
  }
  const { revenueCenterId } = args

  const isWithinFiftyYears = useCallback((utcDateString: string): boolean => {
    const givenDate = new Date(utcDateString)
    const currentDate = new Date()
    const fiftyYearsLater = new Date()
    const fiftyYearsEarlier = new Date()
    fiftyYearsLater.setFullYear(currentDate.getFullYear() + 50)
    fiftyYearsEarlier.setFullYear(currentDate.getFullYear() - 50)
    return (
      (givenDate > currentDate && givenDate <= fiftyYearsLater) ||
      (givenDate < currentDate && givenDate >= fiftyYearsEarlier)
    )
  }, [])

  const utcToCurrentTimeZone = (date: string | null) => {
    if (!date) return null
    const utcDate = new Date(date)
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    )
    // Format the date in 'YYYY-MM-DDTHH:mm' format
    return localDate.toISOString().slice(0, 16)
  }

  const data: DisableKioskFormData = useMemo(() => {
    const status = terminals
      .map(t => t.disable_kiosk_until)
      .reduce((prev, curr) => prev || curr)
    const kiosk_status: KIOSK_STATUS =
      status === null
        ? 'enable_indefinitely'
        : status === DIABLE_WHILE_CLOSED_DATE
          ? 'disable_while_closed'
          : isWithinFiftyYears(status)
            ? 'disable_until'
            : 'disable_indefinitely'
    return {
      kiosk_status,
      disable_kiosk_message,
      disable_until:
        kiosk_status === 'disable_until' ? utcToCurrentTimeZone(status) : null
    }
  }, [disable_kiosk_message, isWithinFiftyYears, terminals])

  useEffect(() => {
    if (revenueCenterId)
      dispatch(fetchRevenueCenterPosSettings(revenueCenterId))
  }, [dispatch, revenueCenterId])

  useEffect(() => {
    if (submitted && !error && !isLoading) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, error, isLoading])

  const getCurrentDateWithAddedYears = useCallback(
    (yearsToAdd: number): string => {
      const currentDate = new Date()
      currentDate.setFullYear(currentDate.getFullYear() + yearsToAdd)
      return currentDate.toISOString()
    },
    []
  )

  const onValuesChanges = useCallback((values: DisableKioskFormData | null) => {
    setFields(prev =>
      prev.map(f =>
        f.name === 'disable_until'
          ? { ...f, hidden: values?.kiosk_status !== 'disable_until' }
          : f
      )
    )
  }, [])

  const submit = useCallback(
    async (values: DisableKioskFormData) => {
      if (kioskSettingsData) {
        setTerminals(prev =>
          prev.map(t => ({
            ...t,
            disable_kiosk_until:
              values.kiosk_status === 'disable_indefinitely'
                ? getCurrentDateWithAddedYears(200)
                : values.kiosk_status === 'disable_until'
                  ? new Date(values.disable_until!).toISOString()
                  : values.kiosk_status === 'disable_while_closed'
                    ? DIABLE_WHILE_CLOSED_DATE
                    : null
          }))
        )
        await Promise.all([
          ...terminals.map(({ pos_terminal_id, ...rest }) =>
            dispatch(
              updatePosTerminal({
                posTerminalId: pos_terminal_id,
                data: {
                  ...rest,
                  disable_kiosk_until:
                    values.kiosk_status === 'disable_indefinitely'
                      ? getCurrentDateWithAddedYears(200)
                      : values.kiosk_status === 'disable_until'
                        ? new Date(values.disable_until!).toISOString()
                        : values.kiosk_status === 'disable_while_closed'
                          ? DIABLE_WHILE_CLOSED_DATE
                          : null
                } as PosTerminal
              })
            )
          ),
          dispatch(
            upsertRevenueCenterPosSettings({
              revenueCenterId,
              data: {
                ...kioskSettingsData,
                disable_kiosk_message: values.disable_kiosk_message
              }
            })
          )
        ])
        setSubmitted(true)
      }
    },
    [
      kioskSettingsData,
      dispatch,
      revenueCenterId,
      terminals,
      getCurrentDateWithAddedYears
    ]
  )

  return (
    <div>
      <ModalHeader
        title="Status"
        subtitle="Please select one of the options to change the status settings of the kiosk"
      />
      <Form
        fields={fields}
        data={data}
        error={editPosTerminalError || error}
        isLoading={isLoading}
        submit={submit}
        isVerticalRadioGroup
        scrollWindow={modalWindow}
        onValuesChange={onValuesChanges}
      />
    </div>
  )
}

const disableKioskOptions: Array<KioskStatusOption> = [
  {
    text: 'Enable indefinitely',
    value: 'enable_indefinitely'
  },
  {
    text: 'Disable indefinitely',
    value: 'disable_indefinitely'
  },
  {
    text: 'Disable during a specific period of time',
    value: 'disable_until'
  },
  {
    text: 'Disable when store is closed',
    value: 'disable_while_closed'
  }
]

const disableKioskFields: FormFieldType[] = [
  {
    fieldType: 'RadioGroup',
    type: 'text',
    name: 'kiosk_status',
    id: 'kiosk_status',
    label: '  ',
    options: disableKioskOptions
  },
  {
    fieldType: 'Input',
    type: 'datetime-local',
    name: 'disable_until',
    id: 'disable_until',
    label: 'Display Kiosk Until',
    hidden: true
  },
  {
    fieldType: 'TextArea',
    type: 'text',
    name: 'disable_kiosk_message',
    id: 'disable_kiosk_message',
    label: 'Display Message'
  }
]

export default DisableKiosk

import React, { useCallback, useMemo } from 'react'
import { Categories, Category, DiscountItemCategory } from 'types'
import { CheckboxItem } from 'components/RevenueCenterTypes/RevenueCenterTypes.styled'
import {
  DiscountedItemAllCategoryContainer,
  DiscountedItemCategoryLabelContainer,
  DiscountedItemsGroup,
  DiscountedItemsGroupList,
  DiscountedItemsGroupTitleContainer
} from '../DiscountedAndRequiredItems.styled'
import TextHeading from '../../TextHeading'
import TextBody from '../../TextBody'

const DiscountedItems = ({
  itemTitle,
  itemValues,
  submit,
  selectedIds,
  updateValues,
  updateAllValues
}: {
  itemTitle: string
  itemValues?: Categories | null
  discountedValues?: Categories | null
  submit?: () => void
  selectedIds?: DiscountItemCategory[]
  updateValues?: (category_id: number, id: number) => void
  updateAllValues?: (category_id: number, ids: number[]) => void
}) => {
  const allItemsCount = useMemo<number>(
    () =>
      (itemValues?.flatMap(item => item.items?.length || 0) ?? []).reduce(
        (i, count) => i + count,
        0
      ),
    [itemValues]
  )

  const getIsAllSelected = useCallback(
    ({ items }: Category) => {
      const allItemsIds = items?.map(({ item }) => item.item_id)
      const selectedItemsIds = selectedIds?.map(({ item_id }) => item_id)
      return allItemsIds?.every(id => selectedItemsIds?.includes(id)) ?? false
    },
    [selectedIds]
  )

  const handleOnUpdateAllValues = useCallback(
    (category: Category) => {
      const { items } = category
      const isSelectedAll = getIsAllSelected(category)
      const allItemsIds = items?.map(({ item }) => item.item_id) ?? []
      updateAllValues?.(category.category_id, isSelectedAll ? [] : allItemsIds)
    },
    [getIsAllSelected, updateAllValues]
  )

  const getSelectedItemsCount = useCallback(
    (category: Category) => {
      const { items } = category
      const allItemsIds = items?.map(({ item }) => item.item_id) ?? []
      const selectedItemsIds = selectedIds?.map(({ item_id }) => item_id)
      return allItemsIds.filter(id => selectedItemsIds?.includes(id)).length
    },
    [selectedIds]
  )

  return (
    <DiscountedItemsGroup>
      <DiscountedItemsGroupTitleContainer>
        <TextHeading size="large">{itemTitle}</TextHeading>
        <TextHeading size="medium">
          {selectedIds?.length || 0}/{allItemsCount} selected
        </TextHeading>
      </DiscountedItemsGroupTitleContainer>
      <DiscountedItemsGroupList>
        {itemValues?.map(item => (
          <React.Fragment key={`${itemTitle}-${item.category_id}`}>
            <DiscountedItemCategoryLabelContainer>
              <TextHeading as="div" size="large">
                {item.full_name} (
                {item.menus?.map(menu => menu.menu.name).join(', ')})
              </TextHeading>
              <DiscountedItemAllCategoryContainer>
                <CheckboxItem
                  name={`${itemTitle}-${item.category_id}-all`}
                  onChange={() => handleOnUpdateAllValues(item)}
                  label="Select All"
                  checked={getIsAllSelected(item)}
                />
                <TextBody size="small">
                  {getSelectedItemsCount(item)}/{item.items?.length || 0}{' '}
                  selected
                </TextBody>
              </DiscountedItemAllCategoryContainer>
            </DiscountedItemCategoryLabelContainer>
            {item.items?.map(i => (
              <CheckboxItem
                key={`${itemTitle}-${item.category_id}-${i.item.item_id}`}
                label={`${i.item.full_name} (${i.item.item_id})`}
                name={`${itemTitle}-${item.category_id}-${i.item.item_id}`}
                checked={
                  selectedIds?.some(
                    selectedItem =>
                      selectedItem.category_id === item.category_id &&
                      selectedItem.item_id === i.item.item_id
                  ) ?? false
                }
                onChange={() =>
                  updateValues?.(item.category_id, i.item.item_id)
                }
              />
            ))}
          </React.Fragment>
        ))}
      </DiscountedItemsGroupList>
    </DiscountedItemsGroup>
  )
}

export default DiscountedItems

import { selectAuthUser } from 'slices/authUser'
import { useAppSelector } from 'app/hooks'
import { truncateText } from 'utils'
import { CampaignContent } from 'types'
import {
  IPhoneCondensedContainer,
  IPhoneFrame,
  IPhoneLockScreenBrandLogo,
  IPhoneLockScreenContent,
  IPhoneLockScreenContentTime,
  IPhoneLockScreenMessage,
  IPhoneLockScreenNotification,
  IPhoneLockScreenSubtitle,
  IPhoneLockScreenTitle,
  Notch,
  Screen
} from './IPhoneCondensed.styled'

interface IPhoneLockScreenProps {
  content?: CampaignContent | null
}

const IPhoneCondensed = ({ content }: IPhoneLockScreenProps) => {
  const { icon } = useAppSelector(selectAuthUser)
  const { body = '', preview_text = '', subject = '' } = content ?? {}
  return (
    <IPhoneCondensedContainer>
      <IPhoneFrame>
        <Screen />
      </IPhoneFrame>
      <Notch />
      <IPhoneLockScreenNotification>
        <IPhoneLockScreenBrandLogo>
          <img src={icon || undefined} alt="" />
        </IPhoneLockScreenBrandLogo>
        <IPhoneLockScreenContent>
          {subject && (
            <IPhoneLockScreenTitle>
              {truncateText(subject, 15)}
            </IPhoneLockScreenTitle>
          )}
          {preview_text && (
            <IPhoneLockScreenSubtitle>
              {truncateText(preview_text, 18)}
            </IPhoneLockScreenSubtitle>
          )}
          {body && (
            <IPhoneLockScreenMessage
              dangerouslySetInnerHTML={{ __html: truncateText(body, 20) }}
            />
          )}
        </IPhoneLockScreenContent>
        <IPhoneLockScreenContentTime>now</IPhoneLockScreenContentTime>
      </IPhoneLockScreenNotification>
    </IPhoneCondensedContainer>
  )
}

export default IPhoneCondensed

import styled from '@emotion/styled'
import TextHeading from 'components/TextHeading'
import { supportedVariableDeliveryProviders } from 'config'
import TiersForm from './TiersForm'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  fetchStoreDistanceDeliveryTiers,
  selectStoreDistanceDeliveryTiers
} from 'slices/storeDistanceDeliveryTiers'
import {
  fetchDistanceDeliveryTiers,
  saveDistanceDeliveryTiers,
  selectDistanceDeliveryTiers
} from 'slices/distanceDeliveryTiers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { RequestStatus, sortTiers } from 'utils'
import { DeliveryProvider, DistanceDeliveryTiers, Surcharge } from 'types'
import { selectSurcharges } from 'slices/surcharges'
import { filterVariableDistanceSurcharges } from 'pages/Settings/AdvancedDelivery/utils'
import Loader from 'components/Loader'
import { selectDeliveryProvidersMap } from 'slices/deliveryProvidersMap'
import Alert from 'components/Alert'

export const SectionTitle = styled(TextHeading)`
  display: block;
  margin: 0.5rem 0;
`

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`
interface AdvancedDeliveryTiersProps {
  storeId?: number | null
  locationId?: number | null
}
const AdvancedDeliveryTiers = ({
  storeId = null,
  locationId = null
}: AdvancedDeliveryTiersProps) => {
  const isStoreTiers = Boolean(storeId && locationId)
  const dispatch = useAppDispatch()
  const [canEdit, setCanEdit] = useState<DeliveryProvider | 'all'>('all')
  const { entities: deliveryProvidersMap } = useAppSelector(
    selectDeliveryProvidersMap(storeId, locationId)
  )
  const { data: surcharges, loading: surchargesLoading } =
    useAppSelector(selectSurcharges)
  const {
    data: tiers,
    loading,
    error
  } = useAppSelector(
    isStoreTiers
      ? selectStoreDistanceDeliveryTiers
      : selectDistanceDeliveryTiers
  )

  const variableSurcharge = useMemo<Surcharge | undefined>(
    () => surcharges.find(filterVariableDistanceSurcharges),
    [surcharges]
  )

  const variableSurchargeId = useMemo<number | undefined>(
    () => variableSurcharge?.surcharge_id,
    [variableSurcharge]
  )
  const handleFetchTiers = useCallback(async () => {
    if (!variableSurchargeId) return
    const fetchTiers = isStoreTiers
      ? fetchStoreDistanceDeliveryTiers
      : fetchDistanceDeliveryTiers
    await dispatch(
      fetchTiers({
        surchargeId: variableSurchargeId,
        ...(isStoreTiers && {
          locationId: Number(locationId),
          storeId: Number(storeId)
        })
      })
    )
  }, [dispatch, isStoreTiers, locationId, storeId, variableSurchargeId])

  useEffect(() => {
    handleFetchTiers()
  }, [handleFetchTiers])

  const submitTiers = async (
    data: DistanceDeliveryTiers,
    provider: DeliveryProvider
  ) => {
    if (!variableSurchargeId) return
    await dispatch(
      saveDistanceDeliveryTiers({
        data,
        provider,
        surchargeId: variableSurchargeId,
        ...(isStoreTiers && {
          locationId: Number(locationId),
          storeId: Number(storeId)
        })
      })
    )
    await handleFetchTiers()
    setCanEdit('all')
  }

  const isLoading =
    loading === RequestStatus.Pending ||
    surchargesLoading === RequestStatus.Pending
  const areProvidersNotSaved = !deliveryProvidersMap.some(
    i => i.brand_delivery_provider_map_id
  )

  return (
    <>
      <SectionHeader>
        <div>
          <SectionTitle size="h4">
            {variableSurcharge?.name ?? 'Variable Delivery Fee'}
          </SectionTitle>
        </div>
      </SectionHeader>
      {areProvidersNotSaved && (
        <Alert alertType="WARNING">
          To edit Doordash or Relay, first save Priority of Deivery Services
          above.
        </Alert>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        !!variableSurchargeId && (
          <>
            {supportedVariableDeliveryProviders.map(provider => (
              <TiersForm
                key={provider}
                error={error}
                initialValues={[...tiers]
                  .filter(t => t.delivery_provider === provider)
                  .sort(sortTiers)}
                isLoading={isLoading}
                onSubmit={submitTiers}
                surchargeId={variableSurchargeId}
                storeId={Number(storeId)}
                locationId={Number(locationId)}
                deliveryProvider={provider}
                canEdit={canEdit}
                setCanEdit={setCanEdit}
              />
            ))}
          </>
        )
      )}
      {}
    </>
  )
}

export default AdvancedDeliveryTiers

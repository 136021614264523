const Knight = ({ size = undefined }: { size?: number }) => {
  return (
    <svg
      width={size}
      height="20"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_213_84)">
        <g clipPath="url(#clip1_213_84)">
          <path
            d="M20.35 6.22002C19.91 5.94002 19.65 5.71002 19.29 5.33002C18.98 5.02002 18.69 4.70002 18.4 4.38002C18.28 4.25002 18.18 4.10002 18.13 3.93002C18.07 3.75002 18.13 3.54002 18.07 3.36002C18.02 3.23002 17.94 3.11002 17.85 3.00002C17.53 2.62002 17.15 2.31002 16.75 2.02002C16.67 1.95002 16.21 1.70002 16.11 1.63002C16.09 1.63002 16.08 1.60002 16.09 1.58002L16.4 0.610019V0.570019C16.3 0.530019 15.23 0.230019 14.12 1.20002C14.12 1.20002 12.4 2.27002 12.11 2.81002C11.38 3.60002 11.73 5.21002 11.87 5.55002C12 5.87002 12.16 6.22002 12.32 6.57002C12.49 6.91002 12.67 7.27002 12.83 7.64002C13 8.00002 13.14 8.38002 13.28 8.75002C13.41 9.13002 13.53 9.50002 13.62 9.87002C13.71 10.24 13.79 10.6 13.83 10.96C13.88 11.3 13.89 11.64 13.89 11.94C13.89 12.55 13.89 13.94 13.87 14.02C13.85 14.1 13.89 13.69 13.89 13.69C13.89 13.69 13.87 13.26 13.89 13.09C13.93 12.75 13.86 12.28 13.79 11.69C13.73 11.11 13.58 10.44 13.33 9.76002C13.2 9.42002 13.06 9.07002 12.9 8.73002C12.73 8.39002 11.54 6.13002 11.28 5.63002C11.22 5.51002 10.39 4.06002 10.26 4.24002C9.58003 5.26002 9.23003 6.20002 9.73003 6.96002C13.8 13.07 11.57 14.12 11.15 15.44C11.07 15.72 11.25 15.99 11.54 15.99H17.15C17.44 15.99 17.61 15.69 17.48 15.42C17.38 14.99 17.55 14.65 17.68 14.4C18.68 12.66 19.52 12.27 18.87 11.16C18.83 11.08 18.79 11.02 18.74 10.94C18.33 10.34 17.83 9.81002 17.53 9.53002C17.11 9.10002 16.85 9.05002 16.51 9.08002C16.08 9.10002 15.65 8.79002 15.29 8.37002C13.8 6.47002 14.49 5.81002 14.78 5.57002C15.34 5.11002 16.54 5.48002 17.24 6.61002C17.6 7.04002 17.91 7.61002 17.83 8.05002C17.9 8.43002 19.09 8.44002 19.21 8.39002L20.42 7.58002C20.42 7.58002 20.46 7.55002 20.46 7.53002C20.52 7.35002 20.73 6.50002 20.32 6.24002V6.21002L20.35 6.22002Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.22 18C18.16 17.85 18.01 17.75 17.85 17.75H10.82C10.65 17.75 10.51 17.85 10.45 18L9.78003 19.52H18.87L18.21 18H18.22Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.79 2.85C15.55 2.94 15.36 3.16 15.39 3.43C15.42 3.69 15.89 3.9 15.89 3.9C16.15 4.02 16.68 4.2 16.68 4.02C16.68 3.79 16.48 3.67 16.38 3.44C16.28 3.21 16.48 3.09 16.48 2.97C16.48 2.85 16.08 2.74 15.79 2.85Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_213_84">
          <rect width="30" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_213_84">
          <rect
            width="12.08"
            height="20.03"
            fill="white"
            transform="translate(9)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Knight

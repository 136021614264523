import styled from '@emotion/styled'
import { Button } from 'components'
import { ModalHeader } from 'components/Modal'
import { ModalWindow } from 'types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { closeModal } from 'slices/modal'
import { selectCampaign } from 'slices/campaign'

const RewardEventReviewButton = styled(Button)`
  text-transform: capitalize;
`

const RewardEventReview = ({
  args: { isSendNow, onSubmit },
  modalWindow
}: {
  args: { isSendNow: boolean; onSubmit: () => void }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { data: campaign } = useAppSelector(selectCampaign)

  const submit = () => {
    onSubmit()
    dispatch(closeModal())
  }

  const renderTitle = () => {
    if (campaign?.campaign_type === 'SINGLE_SEND') {
      return `${isSendNow ? 'send' : 'schedule'}`
    } else if (campaign?.campaign_type === 'RECURRING') {
      return `${isSendNow ? 'start' : 'schedule'}`
    }
  }

  const renderButtonText = () => {
    if (campaign?.campaign_type === 'SINGLE_SEND') {
      return isSendNow ? 'send now' : 'schedule'
    } else if (campaign?.campaign_type === 'RECURRING') {
      return isSendNow ? 'start' : 'schedule'
    }
  }

  return (
    <>
      <ModalHeader
        title={`Ready to ${renderTitle()}?`}
        subtitle={`Are you sure you want to ${renderButtonText()} this reward event?`}
      />
      <RewardEventReviewButton onClick={submit}>
        {renderButtonText()}
      </RewardEventReviewButton>
    </>
  )
}

export default RewardEventReview

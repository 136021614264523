import { Campaign } from './campaign'
import { Discount } from './discounts'

export interface IRewardEventIdentity {
  marketing_reward_event_id: number
}

export interface RewardEvent {
  campaign?: Campaign
  created_at?: string
  discount?: Partial<Discount>
  marketing_reward_event_id?: number
  updated_at?: string
}

export type RewardEvents = RewardEvent[]

export const RewardEventSteps = [
  'reward',
  'recipient',
  'schedule',
  'review'
] as const
export type RewardEventStep = (typeof RewardEventSteps)[number]

const CornerTopLeft = ({ size = 9 }: { size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 8 8"
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        d="M0 4.5C0 2.015 2.015 0 4.5 0H8v1H4.5C2.567 1 1 2.567 1 4.5V8H0V4.5z"
      />
    </svg>
  )
}

export default CornerTopLeft

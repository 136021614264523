import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { MarketingSettings, MarketingBrandSettings } from 'types'
import { showNotification } from './notification'

export interface MarketingSettingsState {
  data: MarketingSettings | null
  brandSettings: MarketingBrandSettings | null
  loading: RequestStatus
  error: RequestError | null
}

export interface MarketingSettingsApi {
  data: MarketingSettings
}

export const initialBrandSettings: MarketingBrandSettings = {
  is_automated_campaign_enabled: false,
  is_email_channel_enabled: false,
  is_marketing_enabled: false,
  is_push_channel_enabled: false,
  is_sms_channel_enabled: false,
  is_web_channel_enabled: false,
  loyalty_provider: 'CHECKMATE',
  brand_id: 0,
  default_sender_name: '',
  sender_email_address: '',
  default_reply_email: '',
  one_signal_app_id: ''
}

const initialState: MarketingSettingsState = {
  data: null,
  loading: RequestStatus.Idle,
  brandSettings: null,
  error: null
}

export const fetchMarketingSettings = createAsyncThunk<
  MarketingSettingsApi | null,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'marketingSettings/fetchMarketingSettings',
  async (_, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `super/brand/marketing/settings`
      )) as MarketingSettingsApi
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchMarketingSettingsList = createAsyncThunk<
  MarketingSettingsApi | null,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'marketingSettings/fetchMarketingSettingsList',
  async (_, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `super/brand/marketing/settings`
      )) as MarketingSettingsApi
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchMarketingBrandSettings = createAsyncThunk<
  MarketingBrandSettings | null,
  number,
  { state: RootState; rejectValue: RequestError }
>(
  'marketingSettings/fetchMarketingBrandSettings',
  async (brandId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `super/brand/marketing/settings/${brandId}`
      )) as MarketingBrandSettings
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchMarketingSettingsForUser = createAsyncThunk<
  MarketingSettingsApi | null,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'marketingSettings/fetchMarketingSettingsForUser',
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `brand-marketing-settings`
      )) as MarketingSettingsApi
      return fulfillWithValue(resp || null)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createMarketingBrandSettings = createAsyncThunk<
  MarketingBrandSettings,
  {
    data: MarketingBrandSettings
  },
  { state: RootState; rejectValue: RequestError }
>(
  'marketingSettings/createMarketingBrandSettings',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `super/brand/marketing/settings`,
        'POST',
        data
      )) as MarketingBrandSettings
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateMarketingBrandSettings = createAsyncThunk<
  MarketingBrandSettings,
  {
    brandId: number
    data: MarketingBrandSettings
  },
  { state: RootState; rejectValue: RequestError }
>(
  'marketingSettings/updateMarketingBrandSettings',
  async ({ brandId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `super/brand/marketing/settings/${brandId}`,
        'PUT',
        data
      )) as MarketingBrandSettings
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const marketingSettingsSlice = createSlice({
  name: 'marketingSettings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMarketingSettingsList.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchMarketingSettingsList.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload?.data || null
        state.error = null
      }
    )
    builder.addCase(
      fetchMarketingSettingsList.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(fetchMarketingSettingsForUser.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchMarketingSettingsForUser.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.brandSettings = payload?.data?.[0] || null
        state.error = null
      }
    )
    builder.addCase(
      fetchMarketingSettingsForUser.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(fetchMarketingBrandSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchMarketingBrandSettings.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.brandSettings = payload
        state.error = null
      }
    )
    builder.addCase(
      fetchMarketingBrandSettings.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.brandSettings = null
        state.error = payload
      }
    )
    builder.addCase(updateMarketingBrandSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      updateMarketingBrandSettings.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.brandSettings = payload
        state.error = null
      }
    )
    builder.addCase(
      updateMarketingBrandSettings.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(createMarketingBrandSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      createMarketingBrandSettings.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.brandSettings = payload
        state.error = null
      }
    )
    builder.addCase(
      createMarketingBrandSettings.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const selectMarketingSettings = (state: RootState) =>
  state.marketingSettings
export const selectMarketingBrandSettings = (state: RootState) =>
  state.marketingSettings?.brandSettings ??
  state.authUser?.brand?.marketing_settings ??
  initialBrandSettings
export default marketingSettingsSlice.reducer

import React from 'react'

const Loyalty = React.lazy(() => import('./Loyalty'))
const LoyaltyRoutes = React.lazy(() => import('./LoyaltyRoutes'))

const LoyaltyProgram = React.lazy(
  () => import('./LoyaltyPrograms/LoyaltyProgram')
)
const LoyaltyProgramsOutlet = React.lazy(
  () => import('./LoyaltyPrograms/LoyaltyProgramsOutlet')
)

const LoyaltyTier = React.lazy(
  () => import('./LoyaltyPrograms/LoyaltyTier/LoyaltyTier')
)

const LoyaltyTiersOutlet = React.lazy(
  () => import('./LoyaltyPrograms/LoyaltyTier/LoyaltyTiersOutlet')
)

const LoyaltyThreshold = React.lazy(
  () => import('./LoyaltyPrograms/LoyaltyThreshold/LoyaltyThreshold')
)

const LoyaltyThresholdsOutlet = React.lazy(
  () => import('./LoyaltyPrograms/LoyaltyThreshold/LoyaltyThresholdsOutlet')
)

const LoyaltyBonus = React.lazy(
  () => import('./LoyaltyPrograms/LoyaltyBonus/LoyaltyBonus')
)

const LoyaltyBonusesOutlet = React.lazy(
  () => import('./LoyaltyPrograms/LoyaltyBonus/LoyaltyBonusesOutlet')
)

const LoyaltyCustomer = React.lazy(
  () => import('./LoyaltyPrograms/LoyaltyCustomers/LoyaltyCustomer')
)

const Reward = React.lazy(() => import('./Rewards/Reward'))

const RewardsOutlet = React.lazy(() => import('./Rewards/RewardsOutlet'))

const RewardEvent = React.lazy(() => import('./RewardEvents/RewardEvent'))

const RewardEventOutlet = React.lazy(
  () => import('./RewardEvents/RewardEventOutlet')
)
const RewardEventsOutlet = React.lazy(
  () => import('./RewardEvents/RewardEventsOutlet')
)

const PromoCode = React.lazy(() => import('./PromoCodes/PromoCode'))

const PromoCodesOutlet = React.lazy(
  () => import('./PromoCodes/PromoCodesOutlet')
)

const Deal = React.lazy(() => import('./Deals/Deal'))

const DealsOutlet = React.lazy(() => import('./Deals/DealsOutlet'))

const GiftCard = React.lazy(() => import('./GiftCards/GiftCard'))

const GiftCardsOutlet = React.lazy(() => import('./GiftCards/GiftCardsOutlet'))

export {
  Deal,
  DealsOutlet,
  Loyalty,
  LoyaltyCustomer,
  LoyaltyRoutes,
  LoyaltyProgram,
  LoyaltyProgramsOutlet,
  LoyaltyTier,
  LoyaltyTiersOutlet,
  LoyaltyThreshold,
  LoyaltyThresholdsOutlet,
  LoyaltyBonus,
  LoyaltyBonusesOutlet,
  PromoCode,
  PromoCodesOutlet,
  Reward,
  RewardsOutlet,
  RewardEvent,
  RewardEventOutlet,
  RewardEventsOutlet,
  GiftCard,
  GiftCardsOutlet
}

import { RequestError, RequestStatus } from '@open-tender/types'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

import { DeliveryProvider, MapDeliveryProvider } from 'types'
import { showNotification } from './notification'

export interface DeliveryProvidersMapAPI {
  data: MapDeliveryProvider[]
  links: {
    next: string
  }
}

export interface DeliveryProvidersMapState {
  entities: MapDeliveryProvider[]
  error: RequestError
  loading: RequestStatus
}

const initialState: DeliveryProvidersMapState = {
  entities: [],
  error: null,
  loading: 'idle'
}

export interface FailedAddingProviders {
  deliveryProvider: DeliveryProvider
  error: void | RequestError
}

export const addDeliveryProviderMap = createAsyncThunk<
  void,
  {
    deliveryProviderMap: Omit<
      MapDeliveryProvider,
      'brand_delivery_provider_map_id'
    >
  },
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryProvidersMap/addDeliveryProviderMap',
  async ({ deliveryProviderMap }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `settings/map-delivery-provider`
      await api?.request(endpoint, 'POST', deliveryProviderMap)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteDeliveryProviderMap = createAsyncThunk<
  void,
  { id: number },
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryProvidersMap/deleteDeliveryProviderMap',
  async ({ id }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `settings/map-delivery-provider/${id}`
      await api?.request(endpoint, 'DELETE')
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const addDeliveryProviderMaps = createAsyncThunk<
  FailedAddingProviders[],
  { data: Omit<MapDeliveryProvider, 'brand_delivery_provider_map_id'>[] },
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryProvidersMap/addDeliveryProviderMaps',
  async ({ data }, { dispatch, rejectWithValue }) => {
    try {
      const promises = data.map(deliveryProviderMap =>
        dispatch(addDeliveryProviderMap({ deliveryProviderMap }))
      )
      const settledPromises = await Promise.all(promises)
      const failedPromises = settledPromises
        .filter(p => p.meta.requestStatus === 'rejected')
        .map(p => ({
          deliveryProvider: p.meta.arg.deliveryProviderMap.delivery_provider,
          error: p.payload
        }))
      return failedPromises
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteDeliveryProviderMaps = createAsyncThunk<
  void,
  { ids: number[] },
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryProvidersMap/deleteDeliveryProviderMaps',
  async ({ ids }, { dispatch, rejectWithValue }) => {
    try {
      const promises = ids.map(id =>
        dispatch(deleteDeliveryProviderMap({ id }))
      )
      await Promise.all(promises)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchDeliveryProvidersMap = createAsyncThunk<
  DeliveryProvidersMapAPI,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryProvidersMap/fetchDeliveryProvidersMap',
  async (_, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `settings/map-delivery-provider`
      const resp = (await api?.request(endpoint)) as DeliveryProvidersMapAPI
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const saveDeliveryProvidersMap = createAsyncThunk<
  FailedAddingProviders[] | void,
  {
    newDeliveryProviders: Omit<
      MapDeliveryProvider,
      'brand_delivery_provider_map_id'
    >[]
    locationId: number | null
    storeId: number | null
  },
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryProvidersMap/saveDeliveryProvidersMap',
  async (
    { newDeliveryProviders, locationId, storeId },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const deliveryProviders = getState().deliveryProvidersMap.entities
      const providersToDelete = deliveryProviders
        .filter(provider => {
          return (
            provider.location_id === locationId && provider.store_id === storeId
          )
        })
        .map(p => p.brand_delivery_provider_map_id)
      await dispatch(deleteDeliveryProviderMaps({ ids: providersToDelete }))
      const failedProviders = await dispatch(
        addDeliveryProviderMaps({ data: newDeliveryProviders })
      )
      dispatch(showNotification('Delivery priorities saved successfully'))
      if (failedProviders.meta.requestStatus === 'fulfilled') {
        return failedProviders.payload as FailedAddingProviders[]
      }
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

const deliveryProvidersMapSlice = createSlice({
  name: 'deliveryProvidersMap',
  initialState,
  reducers: {
    resetDeliveryProvidersMap: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDeliveryProvidersMap.fulfilled, (state, action) => {
        state.entities = action.payload.data
        state.loading = 'idle'
        state.error = null
      })
      .addCase(fetchDeliveryProvidersMap.pending, state => {
        state.loading = 'pending'
      })
      .addCase(fetchDeliveryProvidersMap.rejected, (state, action) => {
        state.error = action.payload
        state.loading = 'idle'
      })
      .addCase(saveDeliveryProvidersMap.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.error = null
      })
      .addCase(saveDeliveryProvidersMap.pending, state => {
        state.loading = 'pending'
      })
      .addCase(saveDeliveryProvidersMap.rejected, (state, action) => {
        state.error = action.payload
        state.loading = 'idle'
      })
  }
})

export const { resetDeliveryProvidersMap } = deliveryProvidersMapSlice.actions

export const selectDeliveryProvidersMap = (
  store_id: number | null,
  location_id: number | null
) =>
  createSelector(
    (state: RootState) => {
      return state.deliveryProvidersMap
    },
    ({ entities, error, loading }) => {
      const storeDeliveryProvidersMap = entities.filter(
        provider =>
          provider.location_id === location_id && provider.store_id === store_id
      )
      return { entities: storeDeliveryProvidersMap, loading, error }
    }
  )

export default deliveryProvidersMapSlice.reducer

import { DistanceDeliveryTier, MapDeliveryProvider } from 'types'

export const defaultProvidersMap: Pick<
  MapDeliveryProvider,
  'delivery_provider'
>[] = [
  { delivery_provider: 'DOORDASH' },
  { delivery_provider: 'UBER_DIRECT' },
  { delivery_provider: 'RELAY' }
]

export const delivery_providers: Record<
  MapDeliveryProvider['delivery_provider'],
  { label: string; logoName: string }
> = {
  DOORDASH: {
    label: 'DoorDash Drive',
    logoName: 'doordash'
  },
  UBER_DIRECT: {
    label: 'Uber Direct',
    logoName: 'uber'
  },
  RELAY: {
    label: 'Relay',
    logoName: 'relay_delivery'
  },
  ONFLEET: {
    label: 'On Fleet',
    logoName: ''
  }
}

export const sortTiers = (
  a: Partial<DistanceDeliveryTier>,
  b: Partial<DistanceDeliveryTier>
) => {
  if (!a.distance_delivery_tier_id) return 1
  if (a.min_distance && b.min_distance) {
    if (parseFloat(a.min_distance) > parseFloat(b.min_distance)) {
      return 1
    } else return -1
  } else return -1
}

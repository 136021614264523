import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { makeTheme } from './utils'
import GlobalStyles from './GlobalStyles'
import './App.css'
import { selectAuthUser, setApi } from 'slices/authUser'
import { selectToken } from 'slices/auth'
import { Main, Modal, Notifications, Placeholder, Sidebar } from 'components'
import AppRoutes from 'components/AppRoutes'
import AuthRoutes from 'components/AuthRoutes'
import Hamburger from 'components/Hamburger'
import { selectGlobal } from 'slices/global'
import { fetchMarketingSettingsForUser } from 'slices/marketingSettings'

const AppView = styled.div<{ showSidebar: boolean }>`
  padding-left: ${props => (props.showSidebar ? '8rem' : '0')};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding-left: 0;
  }
`

const App = () => {
  const dispatch = useAppDispatch()
  const token = useAppSelector(selectToken)
  const { brand, api } = useAppSelector(selectAuthUser)
  const brandId = brand?.brand_id || null
  const { colorScheme, showSidebar } = useAppSelector(selectGlobal)
  const theme = makeTheme(colorScheme)

  useEffect(() => {
    if (!api && token && brandId) {
      dispatch(setApi({ token, brandId, dispatch }))
    }
  }, [api, token, brandId, dispatch])

  useEffect(() => {
    if (brandId) {
      dispatch(fetchMarketingSettingsForUser())
    }
  }, [dispatch, api, brandId])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <BrowserRouter>
        {brand ? (
          <AppView showSidebar={showSidebar}>
            <Sidebar />
            <Hamburger />
            <Main>
              <React.Suspense fallback={<Placeholder />}>
                <AppRoutes />
              </React.Suspense>
            </Main>
          </AppView>
        ) : (
          <AuthRoutes />
        )}
        <Modal />
      </BrowserRouter>
      <Notifications />
    </ThemeProvider>
  )
}

export default App

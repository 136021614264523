import {
  Money,
  NumberString,
  Section,
  Sections,
  SelectOptionInterface,
  SelectOptions,
  SidebarLinkProps,
  StoreAddress
} from 'types'
import { timestampToDateStr } from './datetimes'

export const isString = (str: unknown) => {
  return typeof str === 'string'
}

export const isObject = (obj: unknown) => {
  return typeof obj === 'object' && obj !== null
}

export const isEmpty = (obj: object) => {
  return (
    !obj || (obj.constructor === Object && Object.entries(obj).length === 0)
  )
}

export const isHTML = (str: string) => {
  return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(str)
}

export const isNum = (str: string) => /^\d+$/.test(str)

export const isNullString = (val: unknown) => {
  return typeof val === 'string' && val.toLowerCase() === 'none'
}

export const contains = (
  arr: Array<string | number>,
  values: Array<string | number>
) => {
  return values.filter(i => arr.includes(i)).length > 0
}

export const capitalize = (s: string) => {
  if (!s || !s.length) return ''
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export const snakeCaseToCapitalized = (s: string) => {
  return s
    .split('_')
    .map(i => capitalize(i))
    .join(' ')
}

export const serialize = (obj: Record<string, string>) => {
  let arr = []
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      arr.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  return arr.join('&')
}

export const formatStringToTitleCase = (s: string, makeCapital = true) => {
  const words = s.split('-')
  const formattedWords = words.map(word => {
    if (makeCapital) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    } else {
      return word.toLowerCase()
    }
  })

  return formattedWords.join(' ')
}

export const makeQueryParams = (
  obj: Record<
    string,
    string[] | number[] | string | number | boolean | undefined
  >
) => {
  const params = Object.entries(obj).reduce((args, [key, value]) => {
    return value || value === false
      ? {
          ...args,
          [key]: Array.isArray(value) ? value : value.toString()
        }
      : args
  }, {})
  return `?${serialize(params)}`
}

export const validate = (value: string, type: string): boolean => {
  switch (type) {
    case 'email':
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    default:
      return value.length > 0
  }
}

// export const removeAttributes = <T, Keys extends string[]>(obj: T, keys: Keys) => {
//   return keys.reduce((cleanObj, key) => {
//     return omit<T, K>(cleanObj, key)
//   }, obj as T)
// }

export const omit = <T, K extends string>(obj: T, key: K): Omit<T, K> => {
  const { [key]: omitted, ...rest } = obj
  return rest
}

type ThrottleArgs = string | number | Record<string, unknown>
type ThrottleFunc = (...args: any) => void

export function throttle(func: ThrottleFunc, delay: number) {
  let timeout: ReturnType<typeof setTimeout> | null = null
  return (args?: ThrottleArgs) => {
    if (!timeout) {
      timeout = setTimeout(() => {
        func(args)
        timeout = null
      }, delay)
    }
  }
}

export const getTextEditorCharacterCount = (text: string) => {
  return text.replace(/(<([^>]+)>|&nbsp;)/gi, '').length
}

export const getRandomNumberString = (): string =>
  Math.floor(Math.random() * 1000000000).toString()

export const sortBy = <T>(
  arr: T[],
  key: string | number | symbol,
  order: 'asc' | 'desc' = 'asc'
) => {
  const cloneArr = [...arr]
  return cloneArr.sort((a, b) => {
    if (
      a[key as keyof typeof a] === null &&
      b[key as keyof typeof b] === null
    ) {
      return 0
    }
    if (a[key as keyof typeof a] === null) {
      return order === 'asc' ? 1 : -1
    }
    if (b[key as keyof typeof b] === null) {
      return order === 'asc' ? -1 : 1
    }
    if (order === 'asc') {
      return a[key as keyof typeof a] > b[key as keyof typeof b] ? 1 : -1
    } else {
      return a[key as keyof typeof a] > b[key as keyof typeof b] ? -1 : 1
    }
  })
}

export const slugify = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace('_', '-') // replace _ with -
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const makeMap = (items: Array<Record<string, any>>, id: string) => {
  return items.reduce((obj, item) => {
    obj[item[id]] = item
    return obj
  }, {})
}

export const last = (arr: Array<unknown>) => {
  return arr[arr.length - 1]
}

export const handleArrayInt = (val: string | Array<string>) => {
  const arrayVal = typeof val === 'string' ? val.split(',') : val
  return arrayVal.map(i => parseInt(i)).filter(i => !isNaN(i))
}

export const stringifyOjectValues = <T>(
  obj: Record<keyof T, Array<number>>
): Record<keyof T, string> => {
  return Object.keys(obj).reduce(
    (prev, curr) => {
      return {
        ...prev,
        [curr]: obj[curr as keyof T].join(',')
      }
    },
    {} as Record<keyof T, string>
  )
}

export const covertOjectValuesToArrayInt = <T>(
  obj: Record<keyof T, string>
): Record<keyof T, Array<number>> => {
  return Object.keys(obj).reduce(
    (prev, curr) => {
      return {
        ...prev,
        [curr]: handleArrayInt(obj[curr as keyof T])
      }
    },
    {} as Record<keyof T, Array<number>>
  )
}

export const centsToPrice = (n: number): Money => {
  return `$${(n / 100.0).toFixed(2)}` as Money
}

export const makeSuspendUntil = (suspendUntil: NumberString) => {
  const timestamp = parseInt(suspendUntil)
  return timestamp ? timestampToDateStr(timestamp) : null
}

export const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  )

export const makeCrumbsTitle = (links: SidebarLinkProps[], route?: string) => {
  if (route === undefined) return null
  return findPageTitle(route.split('/'), links)
}

export const findPageTitle = (
  paths: string[],
  links: SidebarLinkProps[]
): null | string => {
  const [first, ...rest] = paths
  const page = links.find(i => i.path === first)
  if (!page) return null
  if (!page.subsections) return page.title
  return findPageTitle(rest, page.subsections)
}

export const makeThemeColor = (color: string) => {
  return color === 'transparent' ? 'transparent' : `#${color}`
}

export const makeThemePadding = (arr: (number | string)[]) => {
  return `${arr[0]}px ${arr[1]}px ${arr[2]}px ${arr[3]}px`
}

export const makeAddress = (address?: StoreAddress) => {
  if (!address) return null
  const { city, postal_code, state, street } = address
  return `${street}, ${city}, ${state} ${postal_code}`
}

export const addCommas = (x: number, d: number) => {
  return x.toFixed(d).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatQuantity = (n: number | string) => {
  try {
    return addCommas(parseFloat(n.toString()), 0)
  } catch {
    return 'NaN'
  }
}

export const formatDollars = (str: number | string | null, space = '') => {
  if (str === null || str === '') return 'n/a'
  const floatPrice = typeof str === 'number' ? str : parseFloat(str)
  return floatPrice < 0
    ? `($${addCommas(Math.abs(floatPrice), 2)})`
    : `$${addCommas(floatPrice, 2)}${space}`
}

export const formatAmount = (amount: string): string => {
  const parsedAmount = parseFloat(amount)
  if (isNaN(parsedAmount)) {
    return amount // Return the original value
  }
  return parsedAmount.toFixed(2)
}

export const omitAttributesWithAllValue = <T>(params: T): T => {
  const result: Partial<T> = {}
  for (const key in params) {
    const value = params[key]
    if (value !== 'ALL' && value !== 'none') {
      result[key] = value
    }
  }
  return result as T
}

export const adminSections: Record<string, Section> = {
  orders: 'ORDERS',
  customers: 'CUSTOMERS',
  loyalty: 'CUSTOMERS',
  marketing: 'MARKETING',
  stores: 'LOCATIONS',
  menus: 'MENUS',
  reporting: 'REPORTING',
  website: 'WEBSITE',
  pages: 'WEBSITE',
  design: 'WEBSITE',
  // editor: 'WEBSITE',
  settings: 'SETTINGS',
  users: 'USERS',
  employees: 'LABOR'
  // onboarding: 'SETTINGS'
}

export const makeUserRoutes = (
  sections: Sections | null,
  isSuper?: boolean,
  marketing?: boolean
) => {
  if (!sections) return []
  let userRoutes = Object.entries(adminSections).reduce(
    (arr: string[], [route, section]) => {
      if (sections.length === 0 || sections.includes(section)) {
        return [...arr, `/${route}`]
      }
      return arr
    },
    []
  )

  if (!marketing) {
    userRoutes = userRoutes.filter(i => i !== '/marketing')
  }

  return isSuper
    ? [...userRoutes, '/editor', '/onboarding', '/super']
    : userRoutes
}

export const findOptionName = (options: SelectOptions, key: string) => {
  const { text } = options.find(i => i.value === key) || {}
  return text || '--'
}

export const getRouteLastSegment = (pathname: string): string => {
  const segments = pathname.split('/')
  return segments.pop() || ''
}

export const createSortMap = (sortOrderConstant: SelectOptionInterface[]) => {
  return sortOrderConstant.reduce(
    (acc, { value }, index) => {
      acc[value as string] = index
      return acc
    },
    {} as Record<string, number>
  )
}

export const unique = (array: unknown[]) => {
  return Array.from(new Set(array))
}

export const getNextCursorPosition = (link: string) => {
  if (!link) return null

  const url = new URL(link)
  const params = new URLSearchParams(url.search)
  return params.get('cursor')
}

export const truncateText = (text: string, maxLength: number): string => {
  if (!text) return ''
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`
  }
  return text
}

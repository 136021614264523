import { useAppDispatch, useAppSelector } from 'app/hooks'
import Alert from 'components/Alert'
import { Avatar } from 'components/Avatar'
import Button from 'components/Button'
import { Dots } from 'components/Icons'
import TextBody from 'components/TextBody'
import { getUrl } from 'pages/Onboarding/Onboarding.utils'
import { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
  DeliveryProvidersMapAPI,
  FailedAddingProviders,
  fetchDeliveryProvidersMap,
  saveDeliveryProvidersMap,
  selectDeliveryProvidersMap
} from 'slices/deliveryProvidersMap'
import { MapDeliveryProvider } from 'types'
import { defaultProvidersMap, delivery_providers, sortBy } from 'utils'
import {
  DeliveryProvider,
  DeliveryProviderIndex,
  DeliveryProvidersCard,
  DeliveryProvidersContainer,
  DeliveryRow,
  DragHandle,
  List,
  Name,
  Section,
  SectionHeader,
  SectionTitle
} from './DeliveryServiceProviders.styled'

interface DeliveryProviderItem
  extends Omit<MapDeliveryProvider, 'brand_delivery_provider_map_id'> {
  brand_delivery_provider_map_id?: number
}

interface AddProviderError {
  provider_name: string
  errorMessage?: string
}

const DeliveryServiceProviders = ({
  storeId = null,
  locationId = null
}: {
  storeId?: number | null
  locationId?: number | null
}) => {
  const dispatch = useAppDispatch()
  const { entities, loading } = useAppSelector(
    selectDeliveryProvidersMap(storeId, locationId)
  )
  const isLoading = loading === 'pending'
  const [warning, setWarning] = useState<AddProviderError[]>([])
  const [readOnly, setReadOnly] = useState(true)
  const [items, setItems] = useState<DeliveryProviderItem[]>([])

  const sortItems = useCallback(
    (savedProviders: DeliveryProviderItem[]) => {
      const deliveryProvidersList: DeliveryProviderItem[] = sortBy(
        savedProviders,
        'role_id'
      )
      defaultProvidersMap.forEach(provider => {
        if (
          !deliveryProvidersList
            .map(p => p.delivery_provider)
            .includes(provider.delivery_provider)
        ) {
          deliveryProvidersList.push({
            delivery_provider: provider.delivery_provider,
            store_id: storeId,
            location_id: locationId,
            role_id: deliveryProvidersList.length + 1
          })
        }
      })
      setItems(deliveryProvidersList)
    },
    [locationId, storeId]
  )
  useEffect(() => {
    sortItems(entities)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortItems])

  useEffect(() => {
    dispatch(fetchDeliveryProvidersMap())
  }, [dispatch])

  const onDragEnd = (result: any) => {
    if (!result.destination) return
    const newItems = Array.from(items)
    const [movedItem] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, movedItem)
    setItems(newItems.map((item, index) => ({ ...item, role_id: index + 1 })))
  }

  const handleOnSubmit = async () => {
    setWarning([])
    const failed = await dispatch(
      saveDeliveryProvidersMap({
        newDeliveryProviders: items,
        locationId,
        storeId
      })
    )
    if (failed.meta.requestStatus === 'fulfilled') {
      const failedProvider = failed.payload as FailedAddingProviders[]
      let warningMsg = failedProvider.reduce((t, pr) => {
        return [
          ...t,
          {
            provider_name: pr.deliveryProvider,
            errorMessage: pr.error?.params?.['$.delivery_provider_id']
          }
        ]
      }, [] as AddProviderError[])

      setWarning(warningMsg)
    }

    const response = await dispatch(fetchDeliveryProvidersMap())
    if (response.meta.requestStatus === 'fulfilled') {
      const responseEntities =
        (response.payload as DeliveryProvidersMapAPI)?.data || []
      sortItems(
        responseEntities.filter(
          e => e.location_id === locationId && e.store_id === storeId
        )
      )
    }
    setReadOnly(true)
  }

  useEffect(() => {
    setReadOnly(true)
    setWarning([])
  }, [locationId, storeId])

  const notSavedYet = !items.some(i => i.brand_delivery_provider_map_id)

  return (
    <Section>
      <SectionHeader>
        <div>
          <SectionTitle size="h4">Priority of Delivery Services</SectionTitle>
          <TextBody>
            Click and drag a service to change the order of priority. The
            service listed first takes the highest priority.
          </TextBody>
        </div>
        <Button
          color="secondary"
          onClick={() => setReadOnly(prevState => !prevState)}
        >
          {readOnly ? 'Edit' : 'Cancel'}
        </Button>
      </SectionHeader>
      {notSavedYet && (
        <Alert alertType="WARNING"> This list is not saved yet </Alert>
      )}
      {warning.length > 0 && (
        <Alert alertType="WARNING">
          Some delivery providers need to be configured at the brand level
          before being mapped.
        </Alert>
      )}
      <DeliveryProvidersCard>
        <DeliveryProvidersContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {provided => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((item, index) => (
                    <Draggable
                      key={item.delivery_provider}
                      draggableId={item.delivery_provider}
                      index={index}
                      isDragDisabled={readOnly}
                    >
                      {provided => (
                        <DeliveryRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <DeliveryProviderIndex>
                            {index + 1}.
                          </DeliveryProviderIndex>
                          <DragHandle {...provided.dragHandleProps}>
                            <Dots
                              size={20}
                              opacity={readOnly ? 0.1 : 1}
                              highlight={10}
                            />
                          </DragHandle>
                          <DeliveryProvider>
                            <Name>
                              {delivery_providers[item.delivery_provider].label}
                            </Name>
                            <Avatar
                              image={getUrl(
                                delivery_providers[item.delivery_provider]
                                  .logoName
                              )}
                              showLabel={false}
                            />
                          </DeliveryProvider>
                          {
                            warning.find(
                              w => w.provider_name === item.delivery_provider
                            )?.errorMessage
                          }
                        </DeliveryRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </DeliveryProvidersContainer>
      </DeliveryProvidersCard>
      {!readOnly && (
        <Button disabled={isLoading} color="primary" onClick={handleOnSubmit}>
          Save
        </Button>
      )}
    </Section>
  )
}

export default DeliveryServiceProviders

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { RewardEvents } from 'types'

export interface RewardEventsState {
  data: RewardEvents
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}
export interface RewardEventsAPI {
  data: RewardEvents
  links: {
    next: string
  }
}

export type GetRewardEventsParams = {
  with_related?: boolean | string
  search?: string
  campaign_status?: string
  reward_event?: string
  'sort-by'?: string
  'sort-direction'?: string
  limit?: number
  cursor?: string
}

const initialState: RewardEventsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchRewardEvents = createAsyncThunk<
  RewardEventsAPI,
  GetRewardEventsParams,
  { state: RootState; rejectValue: RequestError }
>(
  'rewardEvents/fetchRewardEvents',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params
        ? makeQueryParams({
            is_template: false,
            ...params
          })
        : ''
      const endpoint = `reward-events${queryParams}&expand=true`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as RewardEventsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const rewardEventsSlice = createSlice({
  name: 'rewardEvents',
  initialState,
  reducers: {
    resetRewardEvents: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchRewardEvents.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchRewardEvents.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchRewardEvents.rejected, state => {
      state.loading = RequestStatus.Idle
    })
  }
})
export const { resetRewardEvents } = rewardEventsSlice.actions

export const selectRewardEvents = (state: RootState) => state.rewardEvents

export default rewardEventsSlice.reducer

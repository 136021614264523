import { TableHeaderSortable } from 'components'
import { enterpriseLevelOptions } from 'config'
import { CategoryOption, TableHeaderConfig, TableSort } from 'types'

export const brandStatusOptions = [
  { text: 'Churned Cancelled', value: 'churned_canceled' },
  { text: 'Churned In Onboarding', value: 'churned_in_onboarding' },
  { text: 'Blocked Internal', value: 'blocked_internal' },
  { text: 'BLocked Client', value: 'blocked_client' },
  { text: 'On Hold', value: 'on_hold' },
  { text: 'Test', value: 'test' },
  { text: 'Lab', value: 'lab' },
  { text: 'Live', value: 'live' }
]

export const isOrIsNotOperators = [
  { text: 'IS', value: 'equal' },
  { text: 'IS NOT', value: 'not_equal' }
]

export const equalExcludeOperators = [
  { text: 'Equal', value: 'equal' },
  { text: 'Exclude', value: 'exclude' }
]

export const dateOperators = [
  { text: 'Equal', value: 'equal' },
  { text: 'Range', value: 'range' },
  { text: 'Before', value: 'before' },
  { text: 'After', value: 'after' }
]

export const enterpriseStatusOptions = [
  { text: 'Enterprise', value: 'enterprise' },
  { text: 'SMB', value: 'smb' }
]

export const defaultOperators = [
  { text: 'Equal', value: 'equal' },
  { text: 'Not Equal', value: 'not_equal' },
  { text: 'Contains', value: 'contain' },
  { text: 'Exclude', value: 'exclude' }
]

export const brandFormStatusOptions = [
  { text: 'Without Location', value: 'brand_status_no_location' },
  { text: 'Complete', value: 'brand_status_complete' },
  { text: 'InComplete', value: 'brand_status_incomplete' }
]

export const employeeFilters: CategoryOption[] = [
  {
    text: 'Enterprise Status',
    value: 'enterprise_status',
    operatorConfig: { options: equalExcludeOperators, defaultValue: 'equal' },
    keywordConfig: {
      type: 'select',
      defaultValue: 'enterprise',
      options: enterpriseStatusOptions
    }
  },
  {
    text: 'Enterprise Level',
    value: 'enterprise_level',
    operatorConfig: { options: isOrIsNotOperators, defaultValue: 'equal' },
    keywordConfig: {
      type: 'select',
      defaultValue: 1,
      options: enterpriseLevelOptions
    }
  }
]

export const categoryOptions: CategoryOption[] = [
  {
    text: 'Brand Name',
    value: 'brand_name',
    operatorConfig: { options: defaultOperators, defaultValue: 'contain' },
    keywordConfig: { type: 'text', defaultValue: '' }
  },
  {
    text: 'Parent Brand Name',
    value: 'parent_brand_name',
    operatorConfig: { options: defaultOperators, defaultValue: 'equal' },
    keywordConfig: { type: 'text', defaultValue: '' }
  },
  {
    text: 'Brand Status',
    value: 'brand_status',
    keywordConfig: {
      type: 'select',
      defaultValue: 'churned_canceled',
      options: brandStatusOptions
    },
    operatorConfig: {
      options: defaultOperators,
      defaultValue: 'equal',
      disabled: true
    }
  },
  {
    text: 'Brand Form Status',
    value: 'brand_form_status',
    keywordConfig: {
      type: 'select',
      defaultValue: 'without_location',
      options: brandFormStatusOptions
    },
    operatorConfig: {
      options: defaultOperators,
      defaultValue: 'equal',
      disabled: true
    }
  },
  {
    text: 'Brand Creator Email',
    value: 'brand_creator_email',
    operatorConfig: { options: defaultOperators, defaultValue: 'contain' },
    keywordConfig: { type: 'text', defaultValue: '' }
  },
  {
    text: 'Brand Created At',
    value: 'brand_created_at',
    operatorConfig: { options: dateOperators, defaultValue: 'equal' },
    keywordConfig: { type: 'date', defaultValue: '' },
    secondKeywordConfig: {
      type: 'date',
      defaultValue: ''
    }
  },
  {
    text: 'Brand Form Completed At',
    value: 'brand_form_completed_at',
    operatorConfig: { options: dateOperators, defaultValue: 'equal' },
    keywordConfig: { type: 'date', defaultValue: '' },
    secondKeywordConfig: {
      type: 'date',
      defaultValue: ''
    }
  },
  ...employeeFilters
]

export const logicalOperators = [
  { text: 'AND', value: '&' },
  { text: 'OR', value: '|' }
]

export const getArchiveStatus = (archiveStatus: string) => {
  switch (archiveStatus) {
    case 'showAll':
      return undefined
    case 'archived':
      return true
    case 'active':
      return false
  }
}

export const orderingPlatformStatusOptions = [
  {
    text: 'Active',
    value: 'active'
  },
  {
    text: 'Blocked Client',
    value: 'blocked_client'
  },
  {
    text: 'Blocked Internal',
    value: 'blocked_internal'
  },
  {
    text: 'Churned',
    value: 'churned'
  },
  {
    text: 'Paused',
    value: 'paused'
  }
]

export const handleNumericNameOps = (name: string) => {
  switch (name) {
    case 'eat_24':
    case 'kanekt_365':
    case 'orders_2_me':
    case 'say_2_eat':
      return name.replaceAll('_', '')
    case 'order_b_4':
      return 'order_b4'
    default:
      return name
  }
}

const snakeCase = (str: string) => {
  return str
    .replace(/[A-Z]/g, match => `_${match.toLowerCase()}`)
    .replace(/^_/, '')
}

const sanitizeOrderSourceName = (orderSourceName: string) => {
  const sanitizedName = snakeCase(orderSourceName).replace(
    /_(api|customer_pickup|website|storefront|v_2)$/,
    ''
  )
  return handleNumericNameOps(sanitizedName)
}

export const getUrl = (orderSourceName: string) => {
  const match = sanitizeOrderSourceName(orderSourceName)
  switch (match) {
    case 'uber':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/UberEats.png'
    case 'ubereats':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/UberEats.png'
    case 'grubhub_api':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/GrubHub.png'
    case 'grubhub':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/GrubHub.png'
    case 'doordash':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/DoorDash.png'
    case 'postmates':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Postmates.png'
    case 'skipthedishes':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/SkipTheDishes.png'
    case 'deliveroo':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Deliveroo.png'
    case 'bitesquad':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/BiteSquad.png'
    case 'waitr':
      return 'https://d2q8km48t273sa.cloudfront.net/uploads/ordering_platform/icon_image/204/asap__1_.png'
    case 'chownow':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Chownow.png'
    case 'ninefold':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Ninefold.png'
    case 'onosys':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Onosys.png'
    case 'up_menu':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Up-menu.png'
    case 'zuppler':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Zuppler.png'
    case 'eatstreet':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Eatstreet.png'
    case 'ready_to_pay':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Ready.png'
    case 'moduurn':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Moduurn.png'
    case 'thanx':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Thanx.png'
    case 'ordr_ai':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Order-ai.png'
    case 'menulog':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Menulog.png'
    case 'ritual':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Ritual.png'
    case 'mixbowl':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Mixbowl.png'
    case 'seven_rooms':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Seven-rooms.png'
    case 'hungrrr':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Hungrrr.png'
    case 'sharebite':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/Sharebite.png'
    case 'delivery_dudes':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/icons/delivery-dudes.png'
    case 'relay_delivery':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/avatars/relay.png'
    case 'coco':
      return 'https://itsacheckmate-frontend.s3.us-east-2.amazonaws.com/ordering-platforms/avatars/coco.png'
    default:
      return ''
  }
}

export const generateSortableTableHeaders = (
  tableHeadersOptions: TableHeaderConfig[],
  changeSort: (value: string) => void,
  sort: TableSort
) => {
  return tableHeadersOptions.map(i => {
    return i.sorter ? (
      <TableHeaderSortable
        key={i.value}
        value={i.value}
        text={i.text}
        onClick={changeSort}
        sort={sort}
      />
    ) : (
      i.text
    )
  })
}

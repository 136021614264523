const CornerTopRight = ({ size = 9 }: { size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 8 8"
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        d="M8 4.5C8 2.015 5.985 0 3.5 0H0v1h3.5C5.433 1 7 2.567 7 4.5V8h1V4.5z"
      />
    </svg>
  )
}

export default CornerTopRight

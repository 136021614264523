const Overline = ({ size = 9 }: { size?: number }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 13 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.84089 18.4409V6.4326H0.366818V4.59261H11.4648V6.4326H6.99076V18.4409H4.84089Z"
        fill="currentColor"
      />
      <line
        x1="0.0761719"
        y1="1.04611"
        x2="12.1813"
        y2="1.04611"
        stroke="currentColor"
        strokeWidth="1.21052"
      />
    </svg>
  )
}

export default Overline

const LineThrough = ({ size = 9 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.55243 14.4409V2.4326H1.07837V0.59261H12.1764V2.4326H7.70231V14.4409H5.55243Z"
        fill="currentColor"
      />
      <path
        d="M0.787842 8.51423H12.4669V9.67633H0.787842V8.51423Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LineThrough

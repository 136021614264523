import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { MarketingSeedList, SeedListEmails } from 'types/marketingSeedList'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface MarketingSeedListState {
  data: MarketingSeedList | null
  loading: RequestStatus
  error: RequestError
}

const initialState: MarketingSeedListState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchMarketingSeedLists = createAsyncThunk<
  MarketingSeedList,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'marketingSeedList/fetchMarketingSeedLists',
  async (_, { getState, rejectWithValue }) => {
    try {
      const endpoint = 'seed-lists'
      const api = getState().authUser.api
      const resp = await api?.request(endpoint)
      return resp as MarketingSeedList
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createMarketingSeedList = createAsyncThunk<
  SeedListEmails,
  SeedListEmails,
  { state: RootState; rejectValue: RequestError }
>(
  'marketingSeedList/createMarketingSeedList',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `seed-lists`,
        'POST',
        data
      )) as SeedListEmails
      dispatch(showNotification('Marketing seed added successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateMarketingSeedList = createAsyncThunk<
  SeedListEmails,
  SeedListEmails,
  { state: RootState; rejectValue: RequestError }
>(
  'marketingSeedList/updateMarketingSeedList',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `seed-lists`,
        'PUT',
        data
      )) as SeedListEmails
      dispatch(showNotification('Marketing seed updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const marketingSeedListSlice = createSlice({
  name: 'marketingSeedList',
  initialState,
  reducers: {
    resetMarketingSeedList: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchMarketingSeedLists.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchMarketingSeedLists.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchMarketingSeedLists.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload as RequestError
    })
    builder.addCase(createMarketingSeedList.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createMarketingSeedList.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(createMarketingSeedList.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateMarketingSeedList.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateMarketingSeedList.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateMarketingSeedList.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetMarketingSeedList } = marketingSeedListSlice.actions

export const selectMarketingSeedLists = (state: RootState) =>
  state.marketingSeedList

export default marketingSeedListSlice.reducer

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { Campaign, CampaignSegments, CampaignType, RewardEvent } from 'types'

export interface RewardEventState {
  data: RewardEvent | null
  loading: RequestStatus
  validating: RequestStatus
  error: RequestError
  audienceErrors: { [key: string]: string } | null
  scheduleErrors: { [key: string]: string } | null
  discountErrors: { [key: string]: string } | null
  summary: null
  summaryError: RequestError
}
export interface RewardEventAPI {
  data: RewardEvent
  links: {
    next: string
  }
}

export type RewardEventParams = {
  name: string
  description: string
  campaign_type: CampaignType
  is_template: boolean
  is_archived: boolean
  is_generic?: boolean
  marketing_reward_event_id: number
  campaign_id?: number
  discount?: { discount_id: number }
}

export interface FetchRewardEventParams {
  with_related: string
  campaign_id: number
  search?: string
}

export const emptyRewardEvent: RewardEvent = {
  campaign: {
    campaign_id: 0,
    name: '',
    description: '',
    campaign_type: 'SINGLE_SEND',
    created_at: '',
    updated_at: '',
    campaign_status: 'DRAFT',
    campaign_content: [],
    segments: [],
    triggers: [],
    is_archived: false,
    is_template: false
  },
  created_at: '',
  discount: {},
  marketing_reward_event_id: 0,
  updated_at: ''
}

const initialState: RewardEventState = {
  data: emptyRewardEvent,
  loading: RequestStatus.Idle,
  validating: RequestStatus.Idle,
  error: null,
  audienceErrors: null,
  scheduleErrors: null,
  discountErrors: null,
  summary: null,
  summaryError: null
}

export const createRewardEvent = createAsyncThunk<
  RewardEvent,
  Partial<RewardEventParams>,
  { state: RootState; rejectValue: RequestError }
>(
  'rewardEvent/createRewardEvent',
  async (rewardEvent, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `reward-events`
      const resp = (await api?.request(endpoint, 'POST', {
        campaign: {
          campaign_type: rewardEvent.campaign_type,
          description: rewardEvent.description,
          is_archived: rewardEvent.is_archived || false,
          is_generic: rewardEvent.is_generic || false,
          is_template: rewardEvent.is_template || false,
          name: rewardEvent.name
        }
      })) as RewardEvent
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateRewardEvent = createAsyncThunk<
  RewardEvent,
  Partial<RewardEventParams>,
  { state: RootState; rejectValue: RequestError }
>(
  'rewardEvent/updateRewardEvent',
  async ({ ...data }, { getState, rejectWithValue }) => {
    try {
      const { marketing_reward_event_id, discount } = data as RewardEvent
      const api = getState().authUser.api
      const endpoint = `reward-events/${marketing_reward_event_id}`
      const resp = (await api?.request(endpoint, 'PUT', {
        discount_id: discount?.discount_id
      })) as RewardEvent
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchRewardEvent = createAsyncThunk<
  RewardEventAPI,
  FetchRewardEventParams,
  { state: RootState; rejectValue: RequestError }
>(
  'rewardEvent/fetchRewardEvent',
  async ({ campaign_id, ...params }, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const api = getState().authUser.api
      const search = `search=campaign_id:${campaign_id}`
      const endpoint = `reward-events${queryParams}&expand=true&${search}`
      const resp = (await api?.request(endpoint)) as RewardEvent
      return resp as RewardEventAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const validateRewardEvent = createAsyncThunk<
  void,
  { campaign_id: number },
  { state: RootState; rejectValue: RequestError }
>(
  'rewardEvent/validateRewardEvent',
  async ({ campaign_id }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `reward-events/${campaign_id}/review`
      await api?.request(endpoint)
      return fulfillWithValue(undefined)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const activateRewardEvent = createAsyncThunk<
  void,
  { marketing_reward_event_id: number },
  { state: RootState; rejectValue: RequestError }
>(
  'rewardEvent/activateRewardEvent',
  async ({ marketing_reward_event_id }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `reward-events/${marketing_reward_event_id}/activate`
      await api?.request(endpoint, 'POST')
      return
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const pauseRewardEvent = createAsyncThunk<
  void,
  Pick<Campaign, 'campaign_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'rewardEvent/pauseRewardEvent',
  async ({ campaign_id }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `reward-events/${campaign_id}/pause`
      await api?.request(endpoint, 'POST')
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const rewardEventSlice = createSlice({
  name: 'rewardEvent',
  initialState,
  reducers: {
    resetRewardEvent: () => initialState,
    setRewardEvent: (state, action) => {
      state.data = action.payload
    },
    setRewardEventSegments: (
      state,
      action: PayloadAction<CampaignSegments>
    ) => {
      return state
    },
    setSelectedRewardEvent: (state, action) => {
      if (state.data) {
        state.data.discount = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(createRewardEvent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createRewardEvent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.data = payload || initialState.data
      state.error =
        state.discountErrors =
        state.audienceErrors =
        state.scheduleErrors =
          null
    })
    builder.addCase(createRewardEvent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchRewardEvent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchRewardEvent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.data = Array.isArray(payload.data)
        ? payload.data[0]
        : initialState.data
      state.error = null
    })
    builder.addCase(fetchRewardEvent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Failed
      state.error = payload
    })
    builder.addCase(validateRewardEvent.pending, state => {
      state.validating = RequestStatus.Pending
    })
    builder.addCase(validateRewardEvent.fulfilled, (state, { payload }) => {
      state.validating = RequestStatus.Succeeded
      state.discountErrors = state.audienceErrors = state.scheduleErrors = null
    })
    builder.addCase(validateRewardEvent.rejected, (state, { payload }) => {
      state.validating = RequestStatus.Failed
      const errors = payload?.params?.errors as { [key: string]: string }
      const discountId = payload?.params as { [key: string]: string }
      state.audienceErrors = errors
        ? Object.fromEntries(
            Object.entries(errors).filter(([key]) => key.includes('segments'))
          )
        : {}
      state.scheduleErrors = errors
        ? Object.fromEntries(
            Object.entries(errors).filter(([key]) => key.includes('triggers'))
          )
        : {}
      state.discountErrors = discountId
        ? Object.fromEntries(
            Object.entries(discountId).filter(([key]) =>
              key.includes('discount_id')
            )
          )
        : {}
    })
    builder.addCase(activateRewardEvent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(activateRewardEvent.fulfilled, state => {
      state.loading = RequestStatus.Succeeded
      state.error = null
    })
    builder.addCase(activateRewardEvent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Failed
      state.error = payload
    })
    builder.addCase(pauseRewardEvent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(pauseRewardEvent.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(pauseRewardEvent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})
export const {
  resetRewardEvent,
  setRewardEvent,
  setRewardEventSegments,
  setSelectedRewardEvent
} = rewardEventSlice.actions

export const selectRewardEvent = (state: RootState) => state.rewardEvent

export default rewardEventSlice.reducer

import { Button } from 'components'
import { ButtonTabsView } from './ButtonTabs.styled'

export interface ButtonTabOption<T> {
  value: T
  text: string
  disabled?: boolean
}

export type ButtonTabOptions<T> = ButtonTabOption<T>[]

const ButtonTabs = <T extends string>({
  options,
  selected,
  toggle,
  onClear,
  error
}: {
  options: ButtonTabOptions<T>
  selected: T[]
  toggle: (value: T) => void
  onClear?: () => void
  error?: boolean
}) => {
  return (
    <ButtonTabsView error={error}>
      {options.map(option => (
        <Button
          key={option.value}
          size="small"
          color="secondary"
          // color={selected.includes(option.value) ? 'primary' : 'secondary'}
          className={selected.includes(option.value) ? '-selected' : ''}
          onClick={() => toggle(option.value)}
        >
          {option.text}
        </Button>
      ))}
      {onClear && selected.length ? (
        <Button
          key={'clear'}
          size="small"
          color="secondary"
          onClick={() => onClear()}
        >
          &#10006;
        </Button>
      ) : null}
    </ButtonTabsView>
  )
}

export default ButtonTabs

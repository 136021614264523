import styled from '@emotion/styled'

export const RadioLabel = styled.label<{
  variant: 'bordered' | 'default'
  checked: boolean
  isVertical?: boolean
}>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  padding: ${props => (props.variant === 'bordered' ? '1.5rem' : '')};
  border-style: ${props => (props.variant === 'bordered' ? 'solid' : 'none')};
  border-width: ${props =>
    props.variant === 'bordered' ? props.theme.layout.desktop.border : 0};
  border-color: ${props => props.theme.colors.border.primary};
  border-radius: ${props => props.theme.borderRadius.small};
  transition: all 250ms ease;

  &:hover {
    border-color: ${props =>
      props.checked
        ? props.theme.colors.border.primary
        : props.theme.colors.accent.primary};
  }

  @media (max-width: ${props => props.theme.breakpoints.desktop}) {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    min-width: 30%;
    ${props => !props.isVertical && 'max-width: 40%;'}
  }
`

export const RadioInput = styled.input`
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 0.1rem;
  height: 0.1rem;
  padding: 0;
  margin: -0.1rem;
  overflow: hidden;
  white-space: nowrap;
`

export const RadioInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`

export const RadioButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  aspect-ratio: 1;
  border-radius: 100%;
  border-width: 0.1rem;
  border-style: solid;
  transition: all 0.15s ease;
  background-color: ${props => props.theme.colors.background.primary};
  border-color: ${props => props.theme.colors.border.primary};

  input:focus + &,
  input:checked + & {
    border-color: ${props => props.theme.colors.text.primary};
  }

  input:checked + &:before {
    content: '';
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    background-color: ${props => props.theme.colors.text.primary};
  }
`

export const RadioContent = styled.p`
  margin: 2rem 0 1rem 0;
`

export const RadioGroupContainer = styled.div<{ isVertical?: boolean }>`
  display: flex;
  gap: 1.7rem;
  flex-wrap: wrap;
  flex-direction: ${props => (props.isVertical ? ' column' : 'row')};
  @media (max-width: ${props => props.theme.breakpoints.desktop}) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`

import styled from '@emotion/styled'

const PreviewCanvas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.default.color};
  color: ${props => props.theme.colors.default.background};
  cursor: pointer;
  padding: 1.6rem;
  height: 100%;
`

const PreviewCanvasHeading = styled.h2`
  font-weight: bold;
  margin: 4px 0;
`

const NoPreview = ({ text }: { text?: string }) => {
  return (
    <PreviewCanvas>
      <PreviewCanvasHeading>
        {text || 'No Preview Available'}
      </PreviewCanvasHeading>
    </PreviewCanvas>
  )
}

export default NoPreview
